
export const getInviteLink = (roomId, puzzleId) => {
    let url = window.location.protocol + '//' + window.location.host + "/";
    return url.concat(roomId).concat("/").concat(puzzleId).concat("/guest");
}

export const getMemberReinviteLink = (roomId, puzzleId, memberId) => {
    let url = window.location.protocol + '//' + window.location.host + "/";
    return `${url}${roomId}/${puzzleId}/guest/${memberId}`;
}

export const delay = ms => new Promise(res => setTimeout(res, ms));
