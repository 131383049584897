const enPuzzle = (
    {
        'translate.game.toxic_case.instructions.instruction1': `
             <strong> How to play</strong> <br/>
            <br/>A Toxic Case is a cooperative escape room centered around a missing person investigation. To play, each player must have their own smartphone.
            <br/>Each player will receive unique information about the case and a question to answer. Together, you watch a video about the case and work as a team to solve the challenge.
            <br/>Each video begins with an introduction that is part of the story, followed by details relevant to your question. The information is repeated several times, so you won't need to restart the video.
        `,
        'translate.game.toxic_case.instructions.instruction2': `
            <strong> Rules and Options</strong> <br/>
            <br/>When you start a puzzle, a timer begins. You can try to solve the challenge as quickly as possible to earn the highest score, or you can disregard the score and take your time.
            <br/>If you’re having trouble with a challenge, you can ask for a hint. Each challenge includes an explanation hint that provides more details on what to do, as well as two additional hints to help with the puzzle.
            <br/>If the hints aren't enough to solve the puzzle, you can click the Solution option to reveal the answer.
            <br/>Your score is based on the time you take, incorrect answers, and which hints you used. It doesn’t matter if multiple people view the same hint.
            <br/>If you encounter any problems, please have the main player press Reset. This will reset the challenge.
        `,
        'translate.game.toxic_case.instructions.instruction3': `
            <strong> Video Setup</strong> <br/>
            <p>You can watch the videos in two ways: on Vimeo (recommended) or YouTube. We suggest watching them on your TV, though any screen will work.</p>
            <p>For Vimeo (preferably use another phone), go to vimeo.com/showcase/11475329, enter the password 5612, click Start Watching, and stream it to your television</p>
            <p>For YouTube, open the following link in your browser: t.ly/wQQRY. </p>
            
         `,
        'translate.game.toxic_case.instructions.btnText': 'Continue',

        'translate.game.toxic_case.puzzle_name.intro1': "Intro 1",
        'translate.game.toxic_case.puzzle_name.intro2': "Intro 2",
        'translate.game.toxic_case.puzzle_name.deceased_persons': "Deceased people",
        'translate.game.toxic_case.puzzle_name.drone': "Drone",
        'translate.game.toxic_case.puzzle_name.enclose': "Enclose",
        'translate.game.toxic_case.puzzle_name.open_the_door': "Open the door",
        'translate.game.toxic_case.puzzle_name.password': "Password",
        'translate.game.toxic_case.puzzle_name.poison': "Poison",
        'translate.game.toxic_case.puzzle_name.who_did_it': "Who did it",

        'translate.game.toxic_case.video_after.intro1': 'Is everyone ready? Then start the next video - Intro 2 - and press Continue!',
        'translate.game.toxic_case.video_after.intro2': 'Is everyone ready to start the game? Then start the next video - 1. Open the Door - and press Continue!',
        'translate.game.toxic_case.video_after.deceased_persons': 'Is everyone ready for the next challenge? Then start the next video  -  3. Password - and press Continue!',
        'translate.game.toxic_case.video_after.drone': 'Is everybody ready for the next challenge? Then start the next video  - Poison - and press Continue!',
        'translate.game.toxic_case.video_after.enclose': 'Well done, you solved it together! Press Continue to view your total score and challenge your friends!',
        'translate.game.toxic_case.video_after.open_the_door': 'Is everybody ready for the next challenge? Then start the next video  - 2. Deceased People - and press Continue. ',
        'translate.game.toxic_case.video_after.password': 'Is everybody ready for the next challenge? Then start the next video  - Drone - and press Continue!',
        'translate.game.toxic_case.video_after.poison': 'Is everybody ready for the next challenge? Then start the next video  - Who did it - and press Continue!',
        'translate.game.toxic_case.video_after.who_did_it': 'Is everybody ready for the next challenge? Then start the next video  - Enclose - and press Continue!',

        'translate.toxic_case.1_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/coverintro1.webp",

        'translate.toxic_case.1_intro.2p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you begin, ensure that both players have a pen and paper. You’ll learn the game by playing two quick introductory challenges with {{partnerName}}. Open the menu at the top right of this screen, go to Instructions, and set up the video.",
        'translate.toxic_case.1_intro.345p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you begin, ensure that every player has a pen and paper. You’ll learn the game by playing two quick introductory challenges with your fellow player(s). Open the menu at the top right of this screen, go to Instructions, and set up the video.",
        'translate.toxic_case.1_intro.message2.title': "Have you set up the video? Then select ENG subtitles and start the Playlist 'A Toxic Case'. Follow the instructions on the Intro 1 video (Explanation is under the Hints section). Your goal is to send me a six-digit number.",
        'translate.toxic_case.1_intro.message2.errors': "That is not the right answer, but don't worry, this is just practice. Just a quick check. Are you both watching YouTube or Vimeo? Have you found the channel 'CouchClues' and the Playlist 'A Toxic Case'? And are you watching Intro 1? Then open the menu on the top right of your app, open Explanation, then Hint 1 and Hint 2.",
        'translate.toxic_case.1_intro.message3.title': "Well done! Now you can pause the video and click the Results button below.",

        'translate.toxic_case.1_intro.hint.explanation': "Every puzzle comes with an explanation. This explanation will clarify the puzzle if you’re unsure what to do. For this Intro 1 challenge, you must find the same six‐digit number. To do so, all players should click Hint 1.",
        'translate.toxic_case.1_intro.23p.player.hint.hint1': "The first two digits of the code are 96. Be sure to share this information, communication is key in this game! Now check Hint 2.",
        'translate.toxic_case.1_intro.2p.guest01.hint.hint1': "The last two digits are 32. Be sure to share this with your partner, this game is all about communication! Now check Hint 2.",
        'translate.toxic_case.1_intro.345p.guest01.hint.hint1': "The fifth digit is 3. Be sure to share this with your partners, communication is key! Now check Hint 2.",
        'translate.toxic_case.1_intro.345p.guest02.hint.hint1': "The last digit is 2. Be sure to share this with your partners, communication is key! Now check Hint 2",
        'translate.toxic_case.1_intro.45p.player.hint.hint1': "The first digit is a 9. The first digit is 9. Be sure to share this with your partners, communication is key! Now check Hint 2.",
        'translate.toxic_case.1_intro.45p.guest03.hint.hint1': "The second digit is 6. Be sure to share this with your partners, communication is key! Now check Hint 2.",
        'translate.toxic_case.1_intro.5p.guest04.hint.hint1': "The third digit is 1. Be sure to share this with your partner, communication is key! Now check Hint 2.",

        'translate.toxic_case.1_intro.2p.hint.hint2': "Did your partner share the other numbers? The middle digits are 11. You can now solve the puzzle, or view the solution to be sure. Keep in mind that if you choose to view the solution during the game, you won’t earn any points for that challenge.",
        'translate.toxic_case.1_intro.34p.hint.hint2': "Did your partners share the other numbers? The middle digits are 11. You can now solve the puzzle, or view the solution to be sure. Remember, if you choose to view the solution during the game, you won’t earn any points for that challenge.",
        'translate.toxic_case.1_intro.5p.hint.hint2': "Did your partners share the other numbers? The fourth digit is 1. You can now solve the puzzle, or view the solution to be sure. Keep in mind that if you choose to view the solution during the game, you won’t earn any points for that challenge.",

        'translate.toxic_case.1_intro.234p.hint.solution': "The solution is 961132. <br>  Each player received a unique first hint that revealed part of the code. If you shared all the information, you would have obtained the first two digits (96) and the last two digits (32). The second hint, which was the same for everyone, indicated that the middle digits were 11.",
        'translate.toxic_case.1_intro.5p.hint.solution': "The solution is 961132. <br> Each player received a unique first hint that revealed part of the code. If you shared all the information, you would have obtained the first three digits (961) and the last two digits (32). The second hint, which was the same for everyone, indicated that the fourth digit is 1.",


        'translate.toxic_case.2_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/coverintro2.webp",

        'translate.toxic_case.2_intro.timer.title': "Intro 2 - A Toxic Case",
        'translate.toxic_case.2_intro.timer.subtitle': "You will receive more information in:",
        'translate.toxic_case.2_intro.message1.title': "Now it’s time for a real practice puzzle. You will see some images below. Try zooming in on them by tapping and pinching.",
        'translate.toxic_case.2_intro.message2.title': "Watch the Intro 2 video. Your goal is to find a five‐digit code.",
        'translate.toxic_case.2_intro.message2.error': "That isn’t the correct code. To try again, use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.toxic_case.2_intro.message3.title': "That’s right! You will now proceed to the real game. Have fun together!",

        'translate.toxic_case.2_intro_challenging.message2.title': "Watch the Intro 2 video. Your goal is to find a five‐digit code. You’re playing on Challenging difficulty, but the intro challenges remain the same.",
        'translate.toxic_case.2_intro.timer.message1': 'During the game, you will occasionally receive additional messages to help you. If you don’t want any additional help, have the main player switch the difficulty to “Challenging”.',

        'translate.toxic_case.2_intro.hint.explanation': "Your goal is to find a five‐digit number. You’ll need to share what you see on your phone and match it with what appears on the television. Select the labels in the correct order and press Submit.",
        'translate.toxic_case.2_intro.hint.hint1': "The first digit is 5.",
        'translate.toxic_case.2_intro.hint.hint2': "The order on the television is Yellow, Red, Blue, Green, Grey. Which numbers  correspond to these colours?",
        'translate.toxic_case.2_intro.hint.solution': "The solution is Five, Nine, Seven, One, Four. You must select the labels in that order. <br> The order of the colours on the television  is Yellow, Red, Blue, Green and Grey. The yellow label corresponds to 5, the red to 9, the blue to 7, the green to 1 and the grey to 4.",

        'translate.toxic_case.2_intro.four.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2four.webp",
        'translate.toxic_case.2_intro.five.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2five.webp",
        'translate.toxic_case.2_intro.seven.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2seven.webp",
        'translate.toxic_case.2_intro.one.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2one.webp",
        'translate.toxic_case.2_intro.nine.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2nine.webp",

        'translate.toxic_case.2_intro.option1': "One",
        'translate.toxic_case.2_intro.option2': "Two",
        'translate.toxic_case.2_intro.option3': "Three",
        'translate.toxic_case.2_intro.option4': "Four",
        'translate.toxic_case.2_intro.option5': "Five",
        'translate.toxic_case.2_intro.option6': "Six",
        'translate.toxic_case.2_intro.option7': "Seven",
        'translate.toxic_case.2_intro.option8': "Eight",
        'translate.toxic_case.2_intro.option9': "Nine",
        'translate.toxic_case.2_intro.option10': "Zero",


        'translate.open_the_door.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1cover.webp",

        'translate.open_the_door.timer.title': "1. Open the Door - A Toxic Case",
        'translate.open_the_door.timer.subtitle': "You will receive more information in:",
        'translate.open_the_door.message1.title': "We need to get in. He texted me over the last few days, but his previous codes aren't working. I'll send you his messages.",
        'translate.open_the_door.message2.title': "I need the code he used today to open his door. The code consists of 4 digits.",
        'translate.open_the_door.message2.error': "The door won’t open. Is there another code you can think of?",
        'translate.open_the_door.message3.title': "That's the right code, I can open the door now.",

        'translate.open_the_door.timer.message1': 'You may have noticed that in the footage, he does the same four things every day. Is there a connection between the order of his actions and the code?',
        'translate.open_the_door.timer.message2': 'I think I found something. Notice the number 4. It’s the first digit in Friday’s code, the last digit in Saturday’s code, and the second digit in Sunday’s code. In the security footage, the first action on Friday, the last action on Saturday, and the second action on Sunday are identical.',
        'translate.open_the_door.timer.message3': 'Check the clock times. He is using the time on the clock as part of the code for that day.',

        'translate.open_the_door.hint.explanation': "You need to enter a 4-digit code. To do this, match the codes from Friday, Saturday, and Sunday with the corresponding actions in the security footage. Each day, John performs four actions, and each digit in the code is linked to one of these actions. Figure out how this works to determine today’s code.",
        'translate.open_the_door_challenging.hint.explanation': "You need to enter a 4-digit code. To do this, match the codes from Friday, Saturday, and Sunday with the security footage from those days. Each day, John performs four actions. Can you spot a pattern and connect it to the door codes?",

        'translate.open_the_door.hint.hint1': "The numbers 1, 4, and 9 appear each day; only one digit varies. Notice that the number 4 is the first digit in Friday’s code, the last digit in Saturday’s code, and the second digit in Sunday’s code. In the security footage, the first action on Friday, the last action on Saturday, and the second action on Sunday are identical.",
        'translate.open_the_door.hint.hint2': "The time on the clock can help determine one of the digits in that day’s code. For example, if the clock shows 8 o’clock on one day, one of the digits will be 8.",
        'translate.open_the_door_challenging.hint.hint1': "Notice the number 4. It’s the first digit in Friday’s code, the last digit in Saturday’s code, and the second digit in Sunday’s code. Can you match this observation to the security footage?",
        'translate.open_the_door_challenging.hint.hint2': "John uses the time on the clock as part of his code for that day",
        'translate.open_the_door.hint.solution': "1459 is the code for today. <br> Each day, John performs four actions in a different order: blowing out a candle, checking the clock, wiping his feet, and hanging something on the coat rack. Each action corresponds to a digit. On Friday, he blew out a candle first, on Saturday last, and on Sunday second; thus, the number 4 appears as the first digit on Friday, the last digit on Saturday, and the second digit on Sunday. This means that the candle-blowing is linked to the digit 4. The same logic applies to the other three actions, except for looking at the clock. For the clock, on Friday his final action was checking the time, which was 8, so Friday’s code ends with an 8; on Saturday, the second digit is 2; on Sunday, the third digit is 6; and today, the third digit is 5.",

        'translate.open_the_door.friday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1friday.webp",
        'translate.open_the_door.saturday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1saturday.webp",
        'translate.open_the_door.sunday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1sunday.webp",


        'translate.deceased_person.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2cover.webp",

        'translate.deceased_person.timer.title': "2. Deceased People - A Toxic Case",
        'translate.deceased_person.timer.subtitle': "You will receive more information in:",
        'translate.deceased_person.timer.message1': 'I went through more of his belongings. He noted that you can be sure that 9 people were not poisoned. If you have three people remaining, remember that only one has a heart condition.',
        'translate.deceased_person.timer.message2': 'On the back of Simon Carlson’s photo, it states that he was poisoned. Can you determine who the other one is?',

        'translate.deceased_person.message1.title': "What a mess. He was researching unexplained deaths. Apparently, two of them were due to poisoning. I’ll send you his notes.",
        'translate.deceased_person.message2.title': "Let me know if you figure out which two were poisoned. In the meantime, I’ll look around.",
        'translate.deceased_person.message2.error': "I checked, but I believe this contradicts some of his notes. Can you verify it again using the TAG ANSWER link above?",
        'translate.deceased_person.message3.title': "Yes, that makes sense. Now, let’s figure out why he was investigating these two.",

        'translate.deceased_person.hint.explanation': "On the screen, you see images of 12 people. Your goal is to deduce which two were poisoned. Each player receives different descriptions, and you’ll need all the details to identify the two individuals. Focus first on the age differences and on who has a heart condition",
        'translate.deceased_person.hint.hint1': "Start by eliminating the 9 people who were not poisoned. Among the remaining three, remember that only one has a heart condition.",
        'translate.deceased_person.hint.hint2': "One of the two poisoned individuals is Simon Carlson.",
        'translate.deceased_person.hint.solution': "Simon and Sam. <br> Exclude anyone with facial tattoos, those wearing glasses (an accidental injury, so they weren’t poisoned), and Jim and Rahul (they don’t have prescriptions). Then, considering an age difference of 30 years, one of the two must be Simon. Since Simon has a heart condition (earring), the younger person cannot have a heart condition (no earring), meaning it must be Sam.",

        'translate.deceased_person_challenging.hint.hint1': "Charlie Yang and Joe Lopez were not poisoned.",
        'translate.deceased_person_challenging.hint.hint2': "One of the two poisoned individuals is Simon Carlson.",
        'translate.deceased_person_challenging.hint.solution': "Simon and Sam. <br>  Exclude those who are blonde, those with glasses (an accidental injury, so they weren’t poisoned), and Rahul (he doesn’t have prescriptions). Then, considering an age gap of 30 years, one of the two must be either Joe or Simon. If it were Joe, the younger person would have no tattoos (since both cannot have tattoos) and must have a heart condition. Only Charlie fits this description, but he has a tattoo. This means it cannot be Joe, so one of the two must be Simon. Consequently, the younger person does not have a heart condition and isn’t related to Simon (not Jim), so it must be Sam",

        'translate.deceased_person.list.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2list.webp",
        'translate.deceased_person.glasses.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2glasses.webp",
        'translate.deceased_person.30.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/230.webp",
        'translate.deceased_person.rahuljim.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2rahuljim.webp",
        'translate.deceased_person.earrings.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2earrings.webp",
        'translate.deceased_person.tattooface.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2tattooface.webp",
        'translate.deceased_person.oneheart.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2oneheart.webp",
        'translate.deceased_person.prescription.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2prescription.webp",

        'translate.deceased_person_challenging.onenottattoo.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2onenottattoo.webp",
        'translate.deceased_person_challenging.nephew.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2nephew.webp",
        'translate.deceased_person_challenging.blonde.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2blonde.webp",
        'translate.deceased_person_challenging.rahul.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2rahul.webp",
        'translate.deceased_person_challenging.notfamily.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2notfamily.webp",


        'translate.password.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3cover.webp",

        'translate.password.timer.title': "3. Password - A Toxic Case",
        'translate.password.timer.subtitle': "You will receive more information in:",
        'translate.password.message1.title': "Can't log in. He left these notes for me, I'll send them over.",
        'translate.password.message2.title': "We need a 5‐letter password. I think we must locate 5 of these items and use one letter from each to form a word. But which item corresponds to which symbol?",
        'translate.password_challenging.message2.title': "I believe we’re looking for a 5‐letter password. Can you figure it out?",
        'translate.password.message2.error': "Incorrect password. We need to try again.",
        'translate.password.message3.title': "Awake? Anyway, I'm in. Let's see what he has been up to.",

        'translate.password.timer.message1': 'Maybe you’ve already figured it out, but I believe he placed these markers intentionally. How are they connected to the symbols?',
        'translate.password.timer.message2': 'There are five markers scattered around. One of them points to a watch, which is located at the spot marked with an X.',
        'translate.password.timer.message3': 'I think the markers are pointing at a watch, a bowl, a vase, a book and a bottle. For the watch the 2nd letter is an "a", so the word should start with "a"',

        'translate.password.hint.explanation': "We’re looking for a 5‐letter word. To find it, you need to identify five different items in the room. A photo provides a clue to locate these items, and a map offers more details about their positions. Another note indicates which letter to use from each item.",
        'translate.password.hint.hint1': "Focus on the markers. There are five in the room, and one points to a watch located at the spot marked with an X.",
        'translate.password.hint.hint2': "One marker points to a watch at position X, so we must use its second letter, 'a'. The other markers point to a bowl, a vase, a book, and a bottle",
        'translate.password_challenging.hint.explanation': "We’re looking for a 5‐letter word. To find it, you need to locate five different items in the room and use one letter from each. You have a photo to help identify the items and a note to determine their positions.",
        'translate.password_challenging.hint.hint1': "Focus on locating the five markers. Where are they, and what do they point to?",
        'translate.password_challenging.hint.hint2': "A marker points to a watch at location X. The second letter of 'watch' is A, so the 5‐letter word you’re looking for starts with an A.",

        'translate.password.hint.solution': "The password is awake. <br> One note shows a rough layout of the room. The symbols tell you where the items are located. On each location there is a marker pointing at an item that you need. The other note, different for each player, shows which letter you need from each item. The location of the X, has a marker pointing at the watch. The 2nd letter of watch is a. The marker in the location of the triangle, points at a bowl (3rd letter of bowl: w). I is the marker pointing at the vase (2nd letter of vase: a), the dot points at a book (4th letter of book:k), and the marker on the location of the square symbol points at a bottle (6th letter of bottle: e).",

        'translate.password.marker.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3marker.webp",
        'translate.password.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3map.webp",
        'translate.password_challenging.cmap.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3cmap.webp",
        'translate.password.itemsthread.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3itemsthread.webp",
        'translate.password.symbols135.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols135.webp",
        'translate.password.itemsplant.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3itemsplant.webp",
        'translate.password.symbols24.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols24.webp",
        'translate.password.symbols4.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols4.webp",
        'translate.password.symbols15.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols15.webp",
        'translate.password.symbols1.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols1.webp",
        'translate.password.symbols5.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols5.webp",
        'translate.password.symbols2.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols2.webp",
        'translate.password.symbols3.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3symbols3.webp",


        'translate.drone.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cover.webp",

        'translate.drone.timer.title': "4. Drone - A Toxic Case",
        'translate.drone.timer.subtitle': "You will receive more information in:",
        'translate.drone.2p.message1.title': "This area is patrolled by a drone. I’ve managed to hack the footage, but I still don’t know how to get in undetected. John made two sketches of the area—one of the office buildings and one of the research facilities. I will send you and {{partnerName}} one of these sketches each.",
        'translate.drone.345p.message1.title': "This area is patrolled by a drone. I’ve managed to hack the footage, but I still don’t know how to get in undetected. I will send you a sketch that John made of the area.",
        'translate.drone.message2.title': "The drone flies through six zones. Let me know which zones and in what order, so I can figure out a way to evade it.",
        'translate.drone.message2.error': "I saw the drone moving in a different zone than you mentioned. We need to be sure, so please check again using the TAG ANSWER link above and let me know.",
        'translate.drone.message3.title': "That matches what I’m seeing here. I’m going to make a run for it.",

        'translate.drone.timer.message1': 'I focused on the roads and identified the first two zones. The drone first flies over zone Y, then over zone L.',
        'translate.drone.timer.message2': 'There are some zones the drone doesn’t fly over: zones C, E, P, Q, T, and V. I still need to determine the correct order for the remaining zones before I can move.',

        'translate.drone.hint.explanation': "The drone flies over six zones. Figure out the correct order by analyzing the roads and the shapes of the buildings",
        'translate.drone_challenging.hint.explanation': "The drone flies over six zones. Figure out the correct order by analyzing the shapes of the buildings",

        'translate.drone.hint.hint1': "The first zone is Y, and the second zone is L.",
        'translate.drone.hint.hint2': "The zones that are not shown are C, E, P, Q, T, and V.",
        'translate.drone.hint.solution': "The correct order is Y - L - B - A - M - S. <br> In the first zone, you see three buildings: two smaller ones and a larger one in the center, with grass to the right. The building on the right appears to be missing a block in the top right and bottom left. The second zone features a slanted road, so it must be zone L. The third zone displays three buildings, with one in the middle that has a distinctive shape. In the fourth zone, you see a very large building. The fifth zone shows three buildings decreasing in size from left to right, and the sixth zone shows two buildings with shapes that are identifiable as in zone S.",

        'translate.drone.mapred.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapred.webp",
        'translate.drone.mapblue.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapblue.webp",
        'translate.drone.mapall.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapall.webp",
        'translate.drone.4map31.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map31.webp",
        'translate.drone.4map32.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map32.webp",
        'translate.drone.4map33.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map33.webp",
        'translate.drone.4map41.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map41.webp",
        'translate.drone.4map42.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map42.webp",
        'translate.drone.4map43.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map43.webp",
        'translate.drone.4map44.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map44.webp",
        'translate.drone.4map51.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map51.webp",
        'translate.drone.4map52.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map52.webp",
        'translate.drone.4map53.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map53.webp",
        'translate.drone.4map54.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map54.webp",
        'translate.drone.4map55.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map55.webp",

        'translate.drone_challenging.cmaprednoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmaprednoroads.webp",
        'translate.drone_challenging.cmapbluenoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmapbluenoroads.webp",
        'translate.drone_challenging.cmapnoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmapnoroads.webp",
        'translate.drone_challenging.4map31noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map31noroads.webp",
        'translate.drone_challenging.4map32noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map32noroads.webp",
        'translate.drone_challenging.4map33noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map33noroads.webp",
        'translate.drone_challenging.4map41noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map41noroads.webp",
        'translate.drone_challenging.4map42noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map42noroads.webp",
        'translate.drone_challenging.4map43noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map43noroads.webp",
        'translate.drone_challenging.4map44noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map44noroads.webp",
        'translate.drone_challenging.4map51noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map51noroads.webp",
        'translate.drone_challenging.4map52noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map52noroads.webp",
        'translate.drone_challenging.4map53noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map53noroads.webp",
        'translate.drone_challenging.4map54noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map54noroads.webp",
        'translate.drone_challenging.4map55noroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4map55noroads.webp",

        'translate.poison.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5cover.webp",

        'translate.poison.timer.title': "5. Poison - A Toxic Case",
        'translate.poison.timer.subtitle': "You will receive more information in:",

        'translate.poison.message1.title': "He is dead. There is no sign of blunt trauma, so I suspect he was poisoned. I'll examine the scene. Here is some information about possible poisons.",
        'translate.poison.message2.title': "Can you find a poison that matches this situation? Just send me the number.",
        'translate.poison.message2.error': "That poison doesn't match what I'm seeing at the crime scene. We're missing something...",
        'translate.poison.message3.title': "This matches all his symptoms. I think it is the same poison that killed the other two people. This isn’t off-the-shelf, maybe it reacts to another substance?",

        'translate.poison.timer.message1': 'The size of his pupils looks regular to me. But how did he come into contact with the poison?',
        'translate.poison.timer.message2': 'I see sweat on his forehead. An effect of the poison perhaps?',

        'translate.poison.hint.explanation': "You need to enter the number of the poison that was used. The poison has five different attributes (the five columns in the table). Each player has different information about these attributes. You will need to use the information of the players with clues that you see on the video in order to find the right poison.",
        'translate.poison.hint.hint1': "There are 5 clues to find in the video. There is a coffee cup on the floor, he has sweat on his forehead, his fist is clenched, he is foaming, and you can see the shape of his pupils.",
        'translate.poison.hint.hint2': "The coffee cup suggests that his mouth came into contact with the poison. His pupil size is regular.",
        'translate.poison.hint.solution': "71164 matches this situation. <br> John has a clenched fist (pugnus - asphyxiate), is foaming (ketosis - minutes), has regular sized pupils (other type) and sweat on his forehead (synthetic). The coffee cup indicates his mouth came into contact with the poison.",

        'translate.poison.list.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5list.webp",
        'translate.poison.fingerseyestime.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5fingerseyestime.webp",
        'translate.poison.sweatmouthtype.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5sweatmouthtype.webp",
        'translate.poison.eyestime.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5eyestime.webp",
        'translate.poison.sweattype.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5sweattype.webp",
        'translate.poison.fingers.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5fingers.webp",
        'translate.poison.mouth.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5mouth.webp",
        'translate.poison.time.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5time.webp",
        'translate.poison.eyes.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5eyes.webp",


        'translate.who_did_it.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/6cover.webp",

        'translate.who_did_it.timer.title': "6. Who Did It - A Toxic Case",
        'translate.who_did_it.timer.subtitle': "You will receive more information in:",

        'translate.who_did_it.message1.title': "There was a security camera in the corner. Still works with disks. If we know the right order of these recordings, maybe we can figure out who poisoned John.",
        'translate.who_did_it.message2.title': "See if you can find something which can help us arrange the recordings in the right order.",
        'translate.who_did_it.message2.error': "This isn't the correct order, I see some discrepancies between recordings I cannot explain. Can you check again? Use the TAG ANSWER link above.",
        'translate.who_did_it.message3.title': "Makes sense. Let me try arranging the recordings in that order.",

        'translate.who_did_it.timer.message1': 'I think we need to focus on the doormats and the cups at the beginning and end of each video.',
        'translate.who_did_it.timer.message2': 'I have made some notes that I can share with you. I believe that 31 must be the first recording, it ends with one cup and no doormat. Tape 98 starts with 2 cups and no mat, and ends with 1 cup and a mat. 22 starts with 2 cups and a mat, and ends with 3 cups and a mat.',
        'translate.who_did_it.timer.message3': 'The second video should start with 1 cup and no doormat, and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat and ends with 3 cups and a doormat.',

        'translate.who_did_it.hint.explanation': "There are six videos that need to be arranged in the correct order. To determine the order, focus on what appears at the beginning and the end of each video. It might help if each player focuses on a different detail.",
        'translate.who_did_it.hint.hint1': "31 is the first recording. This recording ends with one cup but without a doormat. 98 starts with 2 cups without the mat and ends with 1 cup and a mat. 22 starts with 2 cups and a mat and ends with 3 cups and a mat.",
        'translate.who_did_it_challenging.hint.hint1': "The first video starts with no coffee cups on the counter and a doormat to the right. It ends with 1 cup on the counter and without a doormat.",

        'translate.who_did_it.hint.hint2': "The second video should start with 1 cup and no doormat, and end with 2 cups and a doormat. The third video is 22. This one starts with 2 cups and a doormat, and ends with 3 cups and a doormat.",
        'translate.who_did_it.hint.solution': "The order is 31-84-22-17-98-56. <br> Focus on the number of cups on the counter and the presence of a doormat on the right—you’ll notice that the beginning of one video matches the end of another. There is only one possible sequence: 31: starts with 0 cups and a mat, ends with 1 cup and no mat; 84: starts with 1 cup and no mat, ends with 2 cups and a mat; 22: starts with 2 cups and a mat, ends with 3 cups and a mat; 17: starts with 3 cups and a mat, ends with 2 cups and no mat; 98: starts with 2 cups and no mat, ends with 1 cup and a mat; 56: starts with 1 cup and a mat, and ends with 1 cup and a mat.",

        'translate.enclose.timer.title': "7. Enclose - A Toxic Case",
        'translate.enclose.timer.subtitle': "You will receive more information in:",

        'translate.enclose.message1.title': "We need to deactivate the bombs before she can set them off. There are two people present in the building who can help us. Jennifer, another security guard, and Justin, one of the researchers. I have told them all I know.",
        'translate.enclose.message2.title': "Can you figure out the route that Linda takes? She starts in room 270. Try to identify the rooms we have on camera and pay close attention to what Justin and Jennifer are saying.",
        'translate.enclose.345p.message2.title': "I was able to access only a few of the security cameras, so I suggest you first figure out which rooms we can see on camera. I know we have camera access to room 201 and that Linda starts in room 270, but I need to know the order of the rooms to determine the route she is taking.",
        'translate.enclose_challenging.message2.title': "I was able to access only a few of the security cameras, so I suggest you first figure out which rooms we can see on camera. I know Linda starts in room 270, but we need the order of the rooms to figure out which route she takes!",
        'translate.enclose_challenging.345p.message2.title': "I was able to access only a few of the security cameras, so I suggest you first figure out which rooms we can see on camera. I know Linda starts in room 270, but we need the order of the rooms to figure out which route she takes!",
        'translate.enclose.message2.error': "I'm not sure that this is right. We have only one shot, can you check again? We need 8 rooms in total, including her starting point 270. Use the TAG ANSWER link above and let me know the route she takes.",
        'translate.enclose.message3.title': "OK, let's do this. When Linda enters 204, Justin can go to 266 to deactivate the bomb. I will wait for as long as I can and handle the bomb in 270. Play the next video '8. The End' before pressing results.",

        'translate.enclose.timer.message1': 'I have counted how many times I heard a door open and close. In total we need to identify 8 rooms, including her starting point 270.',
        'translate.enclose.timer.message2': 'Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. Did you spot her on camera in room 201?',

        'translate.enclose.hint.explanation': "Your goal is to determine the route Linda is taking. You need to identify 8 rooms, including her starting point, 270. Focus first on Jennifer and Justin (the researcher): Where are they, and do they hear anything in the adjacent rooms?",
        'translate.enclose_challenging.hint.explanation': "Your goal is to determine the route Linda is taking. In total, you need to identify 8 rooms in the correct order. Start by locating Jennifer and Justin (the researcher) on the map. When do they hear something, and when don’t they? Do they hear anything in the adjacent rooms? What does this tell you about their locations?",

        'translate.enclose.hint.hint1': "Justin, the researcher, is in the toilet near room 281. He only heard something in the room next to him once Linda passed Steve. In total we need to identify 8 rooms, including her starting point 270.",
        'translate.enclose_challenging.hint.hint1': "In total, we need to identify 8 rooms, including her starting point, 270. Identify the rooms where we have camera access by focusing on where the doors are. Which room do we have access to, but never see her on camera?",
        'translate.enclose.hint.hint2': "Jennifer says she did not hear anything, not even in the rooms next to her. This means Linda has not been in rooms 222 and 240. She is not seen on camera in room 201, so she must have gone through 217 to the hallway.",
        'translate.enclose_challenging.hint.hint2': "We have access to the camera in room 201 (the one with the computers), but she is never seen on video there. Jennifer is in the closet in the top right corner and did not hear anything, not even in the rooms next to her. Justin is in the toilet next to room 281.",

        'translate.enclose.hint.solution': "270 - 228 - 266 -  204 - 217 - 281 - 247 - 292. <br> You see Linda first check a bomb in a big room which is not an office (270). Then you don't see her (so she didn't go to 292) and then she appears in another location that has windows and doors both opening outside (it must be 266, so she went through 228). At some point after that, Linda is in the hallway, Steve hears her pass him and only after that does Justin hear her for the first time, in the room next to him. Justin is in the toilet next to 281, so she passes through 281. From 281 she is seen on camera again (must be room 292) and from there she goes back to 270. Because Jennifer didn't hear anything the whole time, not even in the room next to her, the only possibility is that Linda went through room 217. She cannot have gone through 201, because this is a room that we have access to (with the computers), but she wasn't seen on video there.",

        'translate.enclose.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cover.webp",
        'translate.enclose.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7map.webp",
        'translate.enclose.multimap.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7multimap.webp",
        'translate.enclose.201.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7201.webp",
        'translate.enclose.204.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7204.webp",
        'translate.enclose.210.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7210.webp",
        'translate.enclose.217.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7217.webp",
        'translate.enclose.222.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7222.webp",
        'translate.enclose.266.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7266.webp",
        'translate.enclose.227.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7227.webp",
        'translate.enclose.228.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7228.webp",
        'translate.enclose.235.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7235.webp",
        'translate.enclose.239.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7239.webp",
        'translate.enclose.240.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7240.webp",
        'translate.enclose.241.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7241.webp",
        'translate.enclose.247.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7247.webp",
        'translate.enclose.250.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7250.webp",
        'translate.enclose.265.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7265.webp",
        'translate.enclose.267.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7267.webp",
        'translate.enclose.270.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7270.webp",
        'translate.enclose.271.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7271.webp",
        'translate.enclose.281.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7281.webp",
        'translate.enclose.289.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7289.webp",
        'translate.enclose.292.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7292.webp",
        'translate.enclose.293.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7293.webp",
       
        'translate.enclose_challenging.cmapall.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cmapall.webp",
        'translate.enclose_challenging.mapstorage.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cmapstorage.webp",
        'translate.enclose_challenging.maptoilet.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cmaptoilet.webp",
        


    }
);

export default enPuzzle;
