import {alpha, List} from "@mui/material";

export default function PuzzleStatsList({children}){
    return (
        <List
            sx={{
                '.MuiListItemIcon-root': {
                    color: '#FF5757'
                },
                '.MuiListItemText-primary': {
                    color: 'white',
                    fontSize: 14,
                },
                '.MuiListItemText-secondary': {
                    color: '#FF5757',
                    fontSize: 16,
                    fontWeight: 'bold',
                    backgroundColor: alpha('#28354F', 0.3),
                    padding: '8px 12px',
                    borderRadius: 2,
                    marginTop: 1
                }
            }}
        >
            {children}
        </List>
    )
}