import { atom } from "recoil";

const timerStartedAtState = atom({
    key: "timerStartedAtState",
    default: null
});

const timerFinishedAtState = atom({
    key: "timerFinishedAtState",
    default: null
});

const timerConfigState = atom({
    key: "timerConfigState",
    default: null
});

export const DEFAULT_MAX_POLL_ATTEMPTS = 500;

const pollAttemptsState = atom({
    key: "pollAttempts",
    default: 0
});

const maxPollAttemptsState = atom({
    key: "maxPollAttempts",
    default: DEFAULT_MAX_POLL_ATTEMPTS
});

const globalErrorState = atom({
    key: "globalErrorState",
    default: null
});

const globalErrorContextState = atom({
    key: "globalErrorContextState",
    default: null
});

export {
    timerStartedAtState,
    timerConfigState,
    timerFinishedAtState,
    maxPollAttemptsState,
    pollAttemptsState,
    globalErrorState,
    globalErrorContextState,
};
