import Typography from "@mui/material/Typography";
import * as React from "react";
import {getInviteLink} from "../utils/shared";
import {Stack} from "@mui/material";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {DefaultCopyField} from "./copy-field/DefaultCopyField";

const InviteLinkBox = () => {
    const { roomId, puzzleId } = useParams();
    const { t } = useTranslation();
    return (
        <Stack spacing={2}>
            <Typography id="modal-modal-description" fontSize={16} sx={{ paddingLeft: 0 }}>
                {t('copyLink')}
            </Typography>
            <DefaultCopyField
                sx={{marginTop: 0.5}}
                value={getInviteLink(roomId, puzzleId)}
            />
        </Stack>
    )
}

export default InviteLinkBox;
