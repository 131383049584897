import * as React from 'react';
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Stack
} from "@mui/material";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";


export default function LanguageChangeModal({ targetLang, onClose, onConfirm, loading, open }) {
    const { t } = useTranslation('languageChangeModal');
    const {t: tGeneral, i18n} = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle sx={{ color: 'white' }}>
                {t('areYouSure', { lang: tGeneral(`lang.${targetLang}`)})}
            </DialogTitle>
            <DialogContent>
                <DialogContentText sx={{ color: 'white' }}>
                    {t('dialogContent')}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{display: 'flex', justifyContent: 'flex-end'}}>
                {!loading && (
                    <Stack direction="row" gap={3}>
                        <Button
                            size="large"
                            variant="contained"
                            color="error"
                            onClick={onClose}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            color="success"
                            onClick={onConfirm}
                        >
                            {t('confirm')}
                        </Button>
                    </Stack>
                )}
                {loading && <Typography color="white">{t('loading')}</Typography>}
            </DialogActions>
        </Dialog>
    );
}
