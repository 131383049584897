import {useNavigate} from "react-router-dom";
import * as React from "react";
import {useEffect, useState} from "react";
import gameApi from "../api/gameApi";
import Navbar from "../components/navbar/Navbar";
import {Stack} from "@mui/material";
import HintItems from "../components/HintItems";
import useIsGuest from "../hooks/useIsGuest";
import Chatbar from "../components/Chatbar";
import ProblemModal from "../components/ProblemModal";
import {isGuestFromStore, saveRoomInfo} from "../util/storageUtil";
import {useTranslation} from "react-i18next";
import InstructionsModal from "../components/InstructionsModal";
import Game from "../game/Game";
import JoinNewMemberModal from "../components/JoinNewMemberModal";
import PuzzleWaitingStartModal from "../components/puzzle-waiting-start/PuzzleWaitingStartModal";
import {useRoomContext} from "../context/RoomContextProvider";
import {usePuzzleContext} from "../context/PuzzleContextProvider";
import PuzzleWaitingAnswersModal from "../components/puzzle-waiting-answers/PuzzleWaitingAnswersModal";
import AreYouStillThereModal from "../components/are-you-still-there/AreYouStillThereModal";
import gameApiV2 from "../api/gameApiV2";
import OngoingGameInviteModal from "../components/ongoing-game-invite/OngoingGameInviteModal";
import {useProblemContext} from "../context/ProblemContextProvider";
import {usePuzzleWaitingAnswersContext} from "../context/PuzzleWaitingAnswersContextProvider";

export default function PuzzlePage() {
    const {roomId, myMemberId} = useRoomContext();
    const {puzzleId, gameData, isPuzzleStarted, setGameData, isMyMemberKicked, puzzleStateResult} = usePuzzleContext();
    const {gameProblemHelpActive, setGameProblemHelpActive,} = useProblemContext();
    const { hasAllAnswers } = usePuzzleWaitingAnswersContext();

    const navigate = useNavigate();
    const [instructionsOpen, setInstructionsOpen] = useState(false);
    const {isGuest} = useIsGuest(roomId);
    const {i18n} = useTranslation();
    const {t: tPuzzle} = useTranslation('puzzle');
    const [hostName, setHostName] = useState('');
    const [showOngoingGameInviteModal, setShowOngoingGameInviteModal] = useState(false);

    const toggleProblemModal = () => {
        setGameProblemHelpActive(prevOpen => !prevOpen)
    }
    const toggleInstructionModal = () => {
        setInstructionsOpen(prevOpen => !prevOpen)
    }

    useEffect(() => {
        saveRoomInfo(roomId);
    }, [roomId]);

    useEffect(() => {
        const isGuestFromSession = isGuestFromStore(roomId) ?? isGuest;

        /**
         * Validates game (if puzzle is purchased && if puzzle is completed)
         * Also returns saved language (??) TODO: why language here? otherwise could be simply 'validate puzzle'
         */
        gameApi.getGame(roomId, puzzleId, isGuestFromSession)
            .then((data) => {
                data?.lang && i18n.changeLanguage(data?.lang);
                setHostName(data?.hostName)
            })
            .catch(({response}) => {
                navigate('/error', {state: {title: response?.data?.message}})
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId, puzzleId, isGuest, navigate, i18n]);

    useEffect(() => {
        if (isMyMemberKicked && roomId) {
            navigate(`/${roomId}/member-not-found`, { state: { puzzleId }});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMyMemberKicked, roomId]);

    useEffect(() => {
        const handlePopstate = (event) => {
            if (event && event.state) {
                window.location.reload();
            }
        };

        window.addEventListener('popstate', handlePopstate);

        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    window.addEventListener('popstate', function (event) {
        if (event.state && event.state.reload === true) {
            window.location.reload();
        }
    });

    // To force a page reload when the user navigates back
    window.history.pushState({reload: true}, '');

    const getMyGameplay = () => {
        return myMemberId
            ? gameData?.puzzle.gameplayByMemberId[myMemberId]
            : null;
    }


    const getName = (data) => {
        if (data?.gameName !== undefined && data?.puzzle?.name !== undefined) {
            return tPuzzle(data?.gameName) + ': ' + tPuzzle(data?.puzzle?.name);
        }
        return '';
    }

    const [timerMessagesSent, setTimerMessagesSent] = useState([]);

    const onSendTimerMessage = (message) => {
        const req = {message: message};
        setTimerMessagesSent((prev) => [...prev, message])
        gameApiV2.sendTimerMessage(roomId, puzzleId, req, myMemberId)
            .then(response => {
                setGameData(response?.data);
            })
    }

    const results = puzzleStateResult?.data?.result;
    const isPuzzleSolved = !!hasAllAnswers && !!results;

    return (
        <Stack sx={{height: '100%'}}>
            <Navbar
                name={getName(gameData)}
                timerMessages={getMyGameplay()?.timerMessages}
                hintItems={<HintItems hints={getMyGameplay()?.hint} isPuzzleSolved={isPuzzleSolved}/>}
                onResetPuzzle={toggleProblemModal}
                toggleInstructionModal={toggleInstructionModal}
                onSendTimerMessage={onSendTimerMessage}
                timerMessagesSent={timerMessagesSent}
                onInvite={() => setShowOngoingGameInviteModal(true)}
            />
            <Chatbar data={gameData} />
            <ProblemModal
                open={gameProblemHelpActive}
                toggle={toggleProblemModal}
            />
            <InstructionsModal
                open={instructionsOpen}
                toggle={toggleInstructionModal}
                translationPrefix={gameData?.gameName}
            />
            <JoinNewMemberModal hostName={hostName}/>
            <OngoingGameInviteModal
                open={showOngoingGameInviteModal}
                onClose={() => setShowOngoingGameInviteModal(false)}
            />
            <PuzzleWaitingStartModal onProblem={toggleProblemModal}/>
            <PuzzleWaitingAnswersModal onProblem={toggleProblemModal}/>
            <AreYouStillThereModal/>
            <Game matchReady={!!isPuzzleStarted}/>
        </Stack>
    );
}
