const nlPuzzleToxicCase = (
    {
        'translate.game.toxic_case.instructions.instruction1': `
            Hoe werkt het spel?<br/>
            <br/>A Toxic Case is een cooperatief escape room spel over een vermissingszaak. Om het spel te spelen heeft iedere speler zijn eigen smartphone nodig.
            <br/>Iedere speler krijgt dezelfde opdracht, maar jullie ontvangen verschillende informatie. Samen kijken jullie naar dezelfde video over de zaak. Jullie zullen moeten samenwerken om de zaak op te lossen. 
            <br/>Iedere video start met een deel van het verhaal en daarna beelden die betrekking hebben op je opdracht. De beelden die je nodig hebt zullen steeds worden herhaald, dus je hoeft de video niet opnieuw op te starten.
        `,
        'translate.game.toxic_case.instructions.instruction2': `
           Scores en opties<br/>
            <br/>Zodra je een opdracht hebt gekregen start er een timer. Je kunt proberen de opdrachten zo snel mogelijk uit te voeren om een hoge score te halen, maar je kunt dit ook negeren en het rustig op je eigen tijd doen.
            <br/>Als het niet lukt om de opdracht uit te voeren, kun je in het menu rechtsboven een hint gebruiken. Elke puzzel heeft een uitleg, die je kan gebruiken als je geen idee hebt hoe je moet beginnen. De andere hints zijn er voor als je niet verder komt.
            <br/>Kom je er met de hints niet uit, dan kun je op Oplossing klikken om de oplossing en de uitleg te zien.
            <br/>Jullie score wordt bepaald door de tijd, verkeerde antwoorden en welke hints je hebt gebruikt. Beide spelers kunnen dezelfde hint bekijken zonder dat dit extra punten kost.
            <br/>Als iemand technische problemen ervaart, kan speler 1 in het menu op Probleem klikken. Hiermee reset je de puzzel.
        `,
        'translate.game.toxic_case.instructions.instruction3': `
            De videos klaarzetten<br/>
            <br/>Zet nu de videos klaar. Er zijn 2 manieren om de videos te bekijken, Vimeo (aanbevolen) of Youtube. We raden aan om dit op je televisie te doen, maar je kan ook een ander scherm gebruiken. 
            <br/>Gebruik voor Vimeo bij voorkeur een ander apparaat als afstandsbediening. Ga naar vimeo.com/showcase/11475329, klik Start Watching en stream het naar je TV. Selecteer Nederlandse ondertiteling.
            <br/>Voor Youtube kun je meestal een App op je TV gebruiken. Zoek het Couchclues kanaal en speel de Playlist A Toxic Case af. Je kan met het instellingen icoon de ondertiteling op Nederlands zetten.
            <br/>Speelt Intro 1 af met Nederlandse ondertiteling? Klik dan op Ga Verder om het spel te beginnen.
        `,
        'translate.game.toxic_case.instructions.btnText': 'Begrepen!',

        'translate.game.toxic_case.puzzle_name.intro1': "Intro 1",
        'translate.game.toxic_case.puzzle_name.intro2': "Intro 2",
        'translate.game.toxic_case.puzzle_name.deceased_persons': "Deceased people",
        'translate.game.toxic_case.puzzle_name.drone': "Drone",
        'translate.game.toxic_case.puzzle_name.enclose': "Enclose",
        'translate.game.toxic_case.puzzle_name.open_the_door': "Open the door",
        'translate.game.toxic_case.puzzle_name.password': "Password",
        'translate.game.toxic_case.puzzle_name.poison': "Poison",
        'translate.game.toxic_case.puzzle_name.who_did_it': "Who did it",

        'translate.game.toxic_case.video_after.intro1': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - Intro 2 - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.intro2': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.deceased_persons': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 3. Password - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.differences': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.drone': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 5. Poison - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.enclose': 'Gefeliciteerd! Jullie hebben het samen opgelost. Klik op Doorgaan om je totaalscore te bekijken en jullie vrienden uit te dagen!',
        'translate.game.toxic_case.video_after.escape': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.open_the_door': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 2. Deceased persons - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.password': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 4. Drone - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.poison': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 6. Who did it? - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.tools_and_wires': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 1. Open the door - en druk tegelijk op Doorgaan.',
        'translate.game.toxic_case.video_after.who_did_it': 'Is iedereen klaar voor de volgende opdracht? Start de volgende video - 7. Enclose - en druk tegelijk op Doorgaan.',

        'translate.toxic_case.1_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/coverintro1.webp",

        'translate.toxic_case.1_intro.2p.message.title': "Hi {{playerName}}! Welkom bij CouchClues! Voordat je begint, zorg ervoor dat beide spelers een pen en papier hebben. Je leert het spel door 2 korte introductie opdrachten samen met {{partnerName}}. Open nu het menu rechtsboven, lees de instructies en zet de videos klaar.",
        'translate.toxic_case.1_intro.345p.message.title': "Hi {{playerName}}! Welkom bij CouchClues! Voordat je begint, zorg ervoor dat elke speler een pen en papier heeft. Je leert het spel door eerst 2 korte introductie opdrachten te doen met je medespelers. Open nu het menu rechtsboven, lees de instructies en zet de videos klaar.",
        'translate.toxic_case.1_intro.message2.title': "Speelt Intro 1 - A Toxic Case af? Volg dan de instructies op het scherm. De opdracht is om mij een 6 cijferige code te sturen.",
        'translate.toxic_case.1_intro.message2.errors': "Dat is niet het juiste antwoord. Maar geen zorgen, dit is puur om te oefenen. Voor de zekerheid een korte check, kijken jullie allebei naar Youtube, het kanaal CouchClues and de Playlist 'A Toxic Case (Nederlands)'? En kijken jullie naar de video 'Introductie - A Toxic Case'? Open dan het menu rechtsboven, eerst de Uitleg, dan Hint 1 en tot slot Hint 2.",
        'translate.toxic_case.1_intro.message3.title': "Dat is goed! Je kan nu de video pauzeren en op de Score knop drukken hieronder.",

        'translate.toxic_case.1_intro.hint.explanation': "Voor alle opdrachten is een uitleg beschikbaar. Deze kun je gebruiken als vastzit aan het begin van een opdracht en je geen idee hebt wat je moet doen. Voor deze opdracht moeten jullie hetzelfde 6 cijferige getal vinden. Klik hiervoor allemaal nu op Hint 1.",
        'translate.toxic_case.1_intro.23p.player.hint.hint1': "De eerste 2 cijfers van de code zijn 96. Zorg dat je deze informatie deelt, want dit spel draait om samenwerken. Klik nu op Hint 2.",
        'translate.toxic_case.1_intro.2p.guest01.hint.hint1': "De laatste 2 cijfers van de code zijn 32. Zorg dat je deze informatie deelt met je medespeler, want dit spel draait om samenwerken. Klik nu op Hint 2. ",
        'translate.toxic_case.1_intro.345p.guest01.hint.hint1': "Het een-na-laatste cijfer van de code is een 3. Zorg dat je deze informatie deelt, want dit spel draait om samenwerken. Klik nu op Hint 2.",
        'translate.toxic_case.1_intro.345p.guest02.hint.hint1': "Het laatste cijfer van de code is een 2. Zorg dat je deze informatie deelt, want dit spel draait om samenwerken. Klik nu op Hint 2.",
        'translate.toxic_case.1_intro.45p.player.hint.hint1': "Het eerste cijfer van de code is een 9. Zorg dat je deze informatie deelt, want dit spel draait om samenwerken. Klik nu op Hint 2.",
        'translate.toxic_case.1_intro.45p.guest03.hint.hint1': "Het tweede cijfer van de code is een 6. Zorg dat je deze informatie deelt, want dit spel draait om samenwerken. Klik nu op Hint 2.",
        'translate.toxic_case.1_intro.5p.guest04.hint.hint1': "Het derde cijfer van de code is een 1. Zorg dat je deze informatie deelt, want dit spel draait om samenwerken. Klik nu op Hint 2.",

        'translate.toxic_case.1_intro.2p.hint.hint2': "Heeft je medespeler je de andere cijfers verteld? Het derde en vierde cijfer van de code is een 1. Je kan nu de puzzel oplossen, of de Oplossing bekijken als je er niet uitkomt.",
        'translate.toxic_case.1_intro.34p.hint.hint2': "Hebben je medespelers je de andere cijfers verteld? Het derde en vierde cijfer van de code is een 1. Je kan nu de puzzel oplossen, of de Oplossing bekijken als je er niet uitkomt.",
        'translate.toxic_case.1_intro.5p.hint.hint2': "Hebben je medespelers je de andere cijfers verteld? Het vierde cijfer is een 1. Je kan nu de puzzel oplossen, of de Oplossing bekijken als je er niet uitkomt.",

        'translate.toxic_case.1_intro.234p.hint.solution': "De oplossing is 961132. <br> Elke speler heeft bij Hint 1 andere informatie gekregen als onderdeel van de code. Als je alle informatie had gedeeld, kreeg je de eerste twee cijfers (96) en de laatste 2 cijfers (32). Hint 2 was dezelfde voor iedereen, dat het derde en vierde cijfer een 1 was.",
        'translate.toxic_case.1_intro.5p.hint.solution': "De oplossing is 961132. <br> Elke speler heeft bij Hint 1 andere informatie gekregen als onderdeel van de code. Als je alle informatie had gedeeld, kreeg je de eerste drie cijfers (961) en de laatste 2 cijfers (32). Hint 2 was dezelfde voor iedereen, dat het vierde cijfer een 1 was.",


        'translate.toxic_case.2_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/coverintro2.webp",

        'translate.toxic_case.2_intro.timer.title': "Intro 2 - A Toxic Case",
        'translate.toxic_case.2_intro.timer.subtitle': "De oefenopdracht start over:",
        'translate.toxic_case.2_intro.message1.title': "Nu is het tijd voor een oefenopdracht. Jullie zien informatie in de Intro 2 video en een aantal afbeeldingen in de chat. Oefen met inzoomen door 2x op de afbeelding te tikken en daarna je vingers te spreiden.",
        'translate.toxic_case.2_intro.message2.title': "Bekijk de Intro 2 video. Jullie doel is om een 5 cijferige code te vinden. Klik op TAG ANTWOORDEN om het antwoord door te geven",
        'translate.toxic_case.2_intro.message2.error': "Dat is niet de juiste oplossing. Om het nog eens te proberen moet je nog een keer op de TAG ANTWOORDEN link klikken uit het bericht hierboven.",
        'translate.toxic_case.2_intro.message3.title': "Dat klopt! Check jullie score, daarna begint het echte spel. Veel plezier met 'A Toxic Case'!",

        'translate.toxic_case.2_intro.timer.message1': 'Tijdens het spel krijg je soms extra berichten. Deze berichten bevatten extra informatie van de detective die je kunnen helpen. Als je deze berichten niet wil ontvangen kan speler 1 deze uitzetten in het menu.',

        'translate.toxic_case.2_intro.hint.explanation': "Je opdracht is om een 5 cijferig getal te vinden. Je zult elkaar moeten vertellen wat je ziet en dat koppelen aan wat je ziet op de televisie. Tag de antwoorden in de juiste volgorde en klik op 'Verstuur'.",
        'translate.toxic_case.2_intro.hint.hint1': "Het eerste getal is een 5.",
        'translate.toxic_case.2_intro.hint.hint2': "De volgorde van de kleuren op de televisie is Geel, Rood, Blauw, Groen en Grijs. Matcht dit met de getallen die je hebt ontvangen.",
        'translate.toxic_case.2_intro.hint.solution': "De oplossing is Vijf, Negen, Zeven, Een, Vier. Je moet de labels in deze volgorde selecteren. <br> De volgorde van de kleuren in de video zijn Geel, Rood, Blauw, Groen en Grijs. Het gele getal is een 5, het rode een 9, het blauwe een 7, het groene een 1 en het grijze getal een 4.",

        'translate.toxic_case.2_intro.four.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2four.webp",
        'translate.toxic_case.2_intro.five.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2five.webp",
        'translate.toxic_case.2_intro.seven.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2seven.webp",
        'translate.toxic_case.2_intro.one.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2one.webp",
        'translate.toxic_case.2_intro.nine.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/intro2nine.webp",

        'translate.toxic_case.2_intro.option1': "Een",
        'translate.toxic_case.2_intro.option2': "Twee",
        'translate.toxic_case.2_intro.option3': "Drie",
        'translate.toxic_case.2_intro.option4': "Vier",
        'translate.toxic_case.2_intro.option5': "Vijf",
        'translate.toxic_case.2_intro.option6': "Zes",
        'translate.toxic_case.2_intro.option7': "Zeven",
        'translate.toxic_case.2_intro.option8': "Acht",
        'translate.toxic_case.2_intro.option9': "Negen",
        'translate.toxic_case.2_intro.option10': "Nul",


        'translate.open_the_door.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/1cover.webp",

        'translate.open_the_door.timer.title': "1. Open the Door - A Toxic Case",
        'translate.open_the_door.timer.subtitle': "The opdracht start over:",
        'translate.open_the_door.message1.title': "Ik kom er niet in. Hij heeft me de afgelopen dagen geappt, maar zijn eerdere codes werken niet. Ik zal jullie zijn berichten doorsturen.",
        'translate.open_the_door.message2.title': "Ik heb een 4 cijferige code nodig. Kunnen jullie achterhalen wat de code is voor vandaag?",
        'translate.open_the_door.message2.error': "Geprobeerd, maar deze code werkt niet. Heb je nog een ander idee wat de code zou kunnen zijn?",
        'translate.open_the_door.message3.title': "Dat is de juiste code, bedankt! Ik ga naar binnen.",

        'translate.open_the_door.timer.message1': 'John wist waarschijnlijk al dat ik zijn bewakingsbeelden zou kunnen bekijken, dus er moet een verband zijn tussen die beelden en de codes die hij gebruikt.',
        'translate.open_the_door.timer.message2': 'Ik denk dat ik iets heb gevonden. Als je kijkt naar het getal 4, zie je dat het het eerste cijfer is in de code voor vrijdag, het laatste getal in de code voor zaterdag en het tweede getal in de code voor zondag. Op de bewakingsbeelden zie ik dat het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde zijn.',
        'translate.open_the_door.timer.message3': 'Kijk naar de tijden op de klok. Hij gebruikt de tijd op de klok als onderdeel van de code van die dag.',

        'translate.open_the_door.hint.explanation': "Je moet een 4 cijferige code doorsturen. Hiervoor moet je een verband ontdekken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Elk getal van die dag is verbonden met een activiteit die hij doet. Je doel is te ontdekken hoe dit werkt en wat de code voor vandaag zou zijn.",
        'translate.open_the_door_challenging.hint.explanation': "Je moet een code van 4 getallen doorsturen. Je moet het verband zoeken tussen de codes van vrijdag, zaterdag en zondag en de bewakingsbeelden van die dagen. Elke dag zijn er vier dingen die John doet. Kun je een verband vinden met de vier cijferige code van die dag?",

        'translate.open_the_door.hint.hint1': "Hij gebruikt alle dagen de cijfers 1,4 en 9. Alleen het andere cijfer wisselt. Het cijfer 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Op de bewakingsbeelden is het eerste wat hij doet op vrijdag, het laatste wat hij doet op zaterdag en het tweede wat hij doet op zondag hetzelfde.",
        'translate.open_the_door.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag. Bijvoorbeeld als het 8 uur op de klok is, gebruikt hij een 8 in de code.",
        'translate.open_the_door_challenging.hint.hint1': "Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Kun je dit koppellen aan de bewakingsbeelden?",
        'translate.open_the_door_challenging.hint.hint2': "John gebruikt de tijd op de klok als onderdeel van zijn code voor die dag.",
        'translate.open_the_door.hint.solution': "1459 is de code for vandaag. <br> John doet elke dag dezelfde dingen in een verschillende volgorde. Hij blaast de kaars uit, kijkt naar de klok, veegt zijn voeten en hangt iets aan de kapstok. Elke activiteit heeft zijn eigen getal. Hij blaast de kaars als eerste uit op vrijdag, als laatste op zaterdag en als tweede op zondag. Het getal 4 is het eerste getal in de code van vrijdag, het laatste getal in de code van zaterdag en het tweede getal in de code van zondag. Dus het uitblazen van de kaarsen staat voor het getal 4, hij blaast dit vandaag als tweede uit en is dus het tweede getal in de code voor vandaag. Dit werkt hetzelfde voor de andere getallen. Het enige verschil is de klok. Hier gebruikt hij steeds de tijd die dan op de klok staat als code. Dus op vrijdag eindigt de code met een 8, zaterdag is het tweede getal een 2 en vandaag is het derde getal een 5.",

        'translate.open_the_door.friday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl1friday.webp",
        'translate.open_the_door.saturday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl1saturday.webp",
        'translate.open_the_door.sunday.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl1sunday.webp",


        'translate.deceased_person.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2cover.webp",

        'translate.deceased_person.timer.title': "2. Deceased persons - A Toxic Case",
        'translate.deceased_person.timer.subtitle': "De opdracht start over:",
        'translate.deceased_person.timer.message1': 'Ik heb nog wat notities gevonden in zijn prullenbak. Hij wist van 9 personen zeker dat ze niet vergiftigd waren. De notitie dat maar 1 van de vergiftigde personen een hartaandoening heeft is blijkbaar heel belangrijk.',
        'translate.deceased_person.timer.message2': 'Op de achterkant van de foto van Simon Carlson stond dat hij vergiftigd was. Kunnen jullie de andere persoon vinden?',

        'translate.deceased_person.message1.title': "Wat een rommel! John onderzocht sterfgevallen zonder duidelijke doodsoorzaak. Ik stuur je zijn aantekeningen.",
        'translate.deceased_person.message2.title': "Er zijn er 2 vergiftigd. Laat me weten of jullie kunnen achterhalen wie dat zijn. Ik zal kijken of ik nog meer informatie kan vinden.",
        'translate.deceased_person.message2.error': "Ik heb het bekeken, maar volgens mij klopt dit niet met zijn aantekeningen. Kunnen jullie er nog een keer naar kijken? Gebruik dezelfde TAG ANTWOORDEN link uit het bericht hierboven.",
        'translate.deceased_person.message3.title': "Ja, dat lijkt te kloppen met aantekeningen. Misschien kan ik iets over deze mensen vinden op zijn laptop.",

        'translate.deceased_person.hint.explanation': "Op het scherm zie je foto's van 12 mensen. Jouw doel is om te beredeneren welke 2 mensen zijn vergiftigd. Elke speler heeft verschillende aanwijzingen en je hebt alle aanwijzingen nodig om de 2 mensen te vinden die je zoekt. Probeer eerst de mensen te vinden die zeker niet vergiftigd zijn.",
        'translate.deceased_person.hint.hint1': "9 personen zijn zeker niet vergiftigd. Mensen met tattoages op hun gezicht, met een bril (ongeluk, dus niet vergiftigd) en Jim en Rahul (gebruiken geen medicatie).",
        'translate.deceased_person.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson. Dit betekent dat de andere persoon geen hartaandoening heeft.",
        'translate.deceased_person.hint.solution': "Simon en Sam. <br> De mensen met een tattoage in hun gezicht en de personen met een bril (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul en Jim (gebruiken geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee resterende personen Simon. De jongere persoon kan dan geen hartaandoening (oorbel) hebben. Dus valt Anna af en bijft Sam over.",

        'translate.deceased_person_challenging.hint.hint1': "Charlie Yang en Joe Lopez zijn niet vergiftigd.",
        'translate.deceased_person_challenging.hint.hint2': "Een van de twee vergiftigde personen is Simon Carlson.",
        'translate.deceased_person_challenging.hint.solution': "Simon en Sam. <br> De mensen die blond zijn, een bril hebben (zijn overleden aan een ongeluk, dus niet vergiftigd) en Rahul (gebruikt geen medicatie), zijn niet vergiftigd. Omdat er 30 jaar tussen zit, is een van de twee personen Joe of Simon. Als het Joe zou zijn, moet de jongere persoon een hartaandoening hebben. Dit geldt alleen voor Charlie, maar die heeft een tattoo (en minimaal een van de 2 heeft geen tattoo). Dus kan de oudere persoon niet Joe zijn, maar moet het Simon zijn. De jongere persoon kan dan geen hartaandoening hebben en kan geen familie zijn (dus niet Jim). Dus bijft Sam over.",

        'translate.deceased_person.list.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/2list.webp",
        'translate.deceased_person.glasses.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2glasses.webp",
        'translate.deceased_person.30.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl230.webp",
        'translate.deceased_person.rahuljim.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2rahuljim.webp",
        'translate.deceased_person.earrings.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2earrings.webp",
        'translate.deceased_person.tattooface.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2tattooface.webp",
        'translate.deceased_person.oneheart.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2oneheart.webp",
        'translate.deceased_person.prescription.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2prescription.webp",

        'translate.deceased_person_challenging.onenottattoo.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2onenottattoo.webp",
        'translate.deceased_person_challenging.nephew.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2nephew.webp",
        'translate.deceased_person_challenging.blonde.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2blonde.webp",
        'translate.deceased_person_challenging.rahul.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2rahul.webp",
        'translate.deceased_person_challenging.notfamily.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl2notfamily.webp",


        'translate.password.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3cover.webp",

        'translate.password.timer.title': "3. Password - A Toxic Case",
        'translate.password.timer.subtitle': "De opdracht start over:",
        'translate.password.message1.title': "Inloggen lukt niet, ik heb zijn wachtwoord nodig. Ik heb wat notities gevonden, verder vooral veel spullen...",
        'translate.password.message2.title': "Volgens mij hebben we een wachtwoord van 5 letters nodig. Ik denk dat we 5 spullen van zijn lijst moeten vinden van elk ding een letter moeten gebruiken om een woord te vormen. Maar wat hoort bij welk symbool?",
        'translate.password_challenging.message2.title': "Volgens mij zoeken we een wachtwoord van 5 letters.",
        'translate.password.message2.error': "Dit wachtwoord werkt niet. Heb je nog een alternatief?",
        'translate.password.message3.title': "Kamer...? Hoe dan ook, ik zit erin. Eens kijken wat hij aan het doen was.",

        'translate.password.timer.message1': 'Volgens mij heeft hij die markers expres zo laten liggen. Maar wat is de link tussen die markers en de symbolen?',
        'translate.password.timer.message2': 'Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.',
        'translate.password.timer.message3': 'Volgens mij wijzen die markers naar een horloge, een kom, een vaas, een boek en een fles. Voor boek is de 4e letter een k, dus zou het woord moeten beginnen met een "k"',

        'translate.password.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
        'translate.password.hint.hint1': "Er liggen 5 markers in de kamer. Een daarvan wijst naar een boek. Het boek ligt op de positie in de kamer die is aangegeven met X.",
        'translate.password.hint.hint2': "De 5 markers wijzen naar een horloge, een kom, een vaas, een boek en een fles. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",
        'translate.password_challenging.hint.explanation': "Het antwoord is een woord met 5 letters. Om het te vinden moet je 5 verschillende voorwerpen vinden die in de kamer liggen. Een van de notities geeft een aanwijzing waar je deze kan vinden en wat de volgorde is. De andere notitie geeft aan welke letter je moet gebruiken van elk voorwerp.",
        'translate.password_challenging.hint.hint1': "Zoek de 5 markeerstiften. Waar liggen ze en waar wijzen ze naar?",
        'translate.password_challenging.hint.hint2': "Er ligt een markeerstift op plek X die wijst naar een boek. De vierde letter van 'boek' is een 'k'. Dus het woord begint met een 'k'.",

        'translate.password.hint.solution': "Het wachtwoord is kamer. <br> De notitie met de symbolen geeft de plekken weer waar de voorwerpen in de kamer liggen en in welke volgorde ze moeten worden gebruikt. Op elke van deze plekken ligt een markeerstift die wijst naar een voorwerp. De andere notitie verschilt voor elke speler en geeft aan welke letter je nodig hebt van elk voorwerp. Op plek 1 ligt een markeerstift die wijst naar een boek. De vierde letter van boek is 'k'. Op plek 2 ligt een vaas (3e letter: 'a'). Op plek 3 een kom (3e letter: 'm'). Op plek 4 een fles (3e letter: 'e') en op plek 5 een horloge (3e letter: 'r').",

        'translate.password.marker.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/3marker.webp",
        'translate.password.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3map.webp",
        'translate.password_challenging.cmap.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3cmap.webp",
        'translate.password.itemsthread.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3itemsthread.webp",
        'translate.password.symbols135.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols135.webp",
        'translate.password.itemsplant.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3itemsplant.webp",
        'translate.password.symbols24.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols24.webp",
        'translate.password.symbols4.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols4.webp",
        'translate.password.symbols15.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols15.webp",
        'translate.password.symbols1.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols1.webp",
        'translate.password.symbols5.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols5.webp",
        'translate.password.symbols2.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols2.webp",
        'translate.password.symbols3.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl3symbols3.webp",


        'translate.drone.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cover.webp",

        'translate.drone.timer.title': "4. Drone - A Toxic Case",
        'translate.drone.timer.subtitle': "De opdracht start over:",
        'translate.drone.2p.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. John heeft 2 verschillende schetsen gemaakt van het gebied. Ik stuur jou en {{partnerName}} een verschillende schets op. Kijk naar zowel de gebouwen als de wegen. ",
        'translate.drone.345p.message1.title': "Dit gebied wordt bewaakt door een drone. Het is gelukt om de beelden te hacken, maar ik weet nog niet hoe ik ongezien binnen moet komen. Ik zal je een schets sturen die John heeft gemaakt van dit gebied.",
        'translate.drone.message2.title': "Laat me weten in welke zones de drone vliegt en in welke volgorde, zodat ik een manier kan vinden om erlangs te komen.",
        'translate.drone.message2.error': "Ik zag de drone ergens anders vliegen dan wat je appte. We moeten er zeker van zijn. Gebruik de TAG ANTWOORD link uit het hierboven nog een keer en laat het me weten.",
        'translate.drone.message3.title': "Dat lijkt te kloppen. Ik ga een poging wagen!",

        'translate.drone.timer.message1': 'Ik heb gekeken naar de wegen en zo de eerste 2 zones gevonden. De drone vliegt eerst over zone Y en daarna over zone L.',
        'translate.drone.timer.message2': 'Er zijn een aantal zones waar de drone niet komt. Dit zijn zones C,E,P,Q,T en V. Ik moet wel nog de juiste volgorde te weten zodat ik kan timen wanneer ik moet gaan.',

        'translate.drone.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de wegen en de gebouwen.",
        'translate.drone_challenging.hint.explanation': "De drone vliegt in 6 zones. Het doel is de juiste zones en de juiste volgorde te vinden. Dit doe je door te kijken naar de vorm van de gebouwen..",

        'translate.drone.hint.hint1': "De eerste zone is zone Y en de tweede zone is zone L",
        'translate.drone.hint.hint2': "De zones waar de drone niet overheen vliegt zijn C, E, P, Q, T, V.",
        'translate.drone.hint.solution': "De juiste volgorde is Y - L - B - A - M - S. <br> In de eerste zone zie je 3 gebouwen, 2 kleinere en 1 grotere in het midden, met gras aan de rechterkant. Het rechtergebouw heeft een opvallende vorm. In de 2e zone staan de gebouwen schuin tegenover elkaar, dus dit moet zone L zijn. In de 3e zone zijn 3 gebouwen te zien, waarvan de middelste een opvallende vorm heeft. In de 4e zone zie je een heel groot gebouw. De vijfde zone laat 3 gebouwen zien, die van links naar rechts steeds iets kleiner lijken te worden. De 6e zone laat 2 gebouwen zien met vormen die je kan herkennen in zone S.",

        'translate.drone.mapred.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapred.webp",
        'translate.drone.mapblue.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapblue.webp",
        'translate.drone.mapall.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4mapall.webp",

        'translate.drone_challenging.cmaprednoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmaprednoroads.webp",
        'translate.drone_challenging.cmapbluenoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmapbluenoroads.webp",
        'translate.drone_challenging.cmapnoroads.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/4cmapnoroads.webp",


        'translate.poison.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/5cover.webp",

        'translate.poison.timer.title': "5. Poison - A Toxic Case",
        'translate.poison.timer.subtitle': "De opdracht start over:",

        'translate.poison.message1.title': "Hij is dood, geen duidelijke verwondingen. Ik vermoed dat hij is vergiftigd. Ik stuur jullie wat algemene informatie door en zal kijken wat ik kan vinden.",
        'translate.poison.message2.title': "Kunnen jullie uitzoeken of er een type gif bestaat dat past bij deze situatie? Ik heb alleen het bijbehorende nummer nodig.",
        'translate.poison.message2.error': "Dat gif past niet helemaal bij wat ik hier zie. Zien we iets over het hoofd?",
        'translate.poison.message3.title': "Dat lijkt te kloppen met wat ik hier zie. Ik denk dat het hetzelfde gif is waardoor die andere 2 mensen zijn overleden. Het is geen kant en klaar gif, misschien reageert het op een andere vloeistof?",

        'translate.poison.timer.message1': 'Ik zou zeggen dat zijn pupillen een gebruikelijk formaat hebben. Maar hoe is hij in contact gekomen met dit gif?',
        'translate.poison.timer.message2': 'Hij heeft zweet op zijn voorhoofd. Misschien een effect van het gif?',

        'translate.poison.hint.explanation': "Je doel is om het nummer van het juiste gif door te sturen. Het gif heeft 5 verschillende eigenschappen (de 5 kolommen in de tabel) en de spelers hebben verschillende informatie. Je zult de informatie van de spelers moeten combineren met aanwijzingen uit de video.",
        'translate.poison.hint.hint1': "Er zijn 5 aanwijzingen in de video te vinden. Er ligt een koffie kopje op de vloer, hij heeft zweet op zijn voorhoofd, zijn vingers maken een vuist, er komt kwijl uit zijn mond en je kan zijn pupillen zien.",
        'translate.poison.hint.hint2': "Het koffiekopje is een aanwijzing dat het gif via zijn mond zijn lichaam is binnengekomen. Er is niets ongebruikelijks te zien aan zijn pupillen.",
        'translate.poison.hint.solution': "71164 is het gif dat past bij deze situatie. <br> John heeft zijn vingers in een vuist (pugnus - luchtwegen), er komt kwijl uit zijn mond (kitosis - minuten), er is niets ongebruikelijks aan zijn pupillen (andere types) en hij heeft zweet op zijn voorhoofd (synthetisch). Het koffiekopje geeft aan dat hij via de mond in contact is gekomen met het gif.",

        'translate.poison.list.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5list.webp",
        'translate.poison.fingerseyestime.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5fingerseyestime.webp",
        'translate.poison.sweatmouthtype.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5sweatmouthtype.webp",
        'translate.poison.eyestime.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5eyestime.webp",
        'translate.poison.sweattype.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5sweattype.webp",
        'translate.poison.fingers.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5fingers.webp",
        'translate.poison.mouth.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5mouth.webp",
        'translate.poison.time.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5time.webp",
        'translate.poison.eyes.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl5eyes.webp",


        'translate.who_did_it.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/6cover.webp",

        'translate.who_did_it.timer.title': "6. Who did it - A Toxic Case",
        'translate.who_did_it.timer.subtitle': "De opdracht start over:",

        'translate.who_did_it.message1.title': "Er staat een bewakingscamera in de hoek. Werkt nog met tapes. Als we de volgorde van de beelden weten, zien we misschien wie John heeft vergiftigd.",
        'translate.who_did_it.message2.title': "Kijk of je iets ziet wat ons kan helpen om de beelden in de juiste volgorde te plaatsen.",
        'translate.who_did_it.message2.error': "Deze volgorde klopt niet volgens mij, de videos sluiten niet op elkaar aan. Kun je nog een keer kijken? Gebruik bovenstaande TAG ANTWOORDEN link opnieuw.",
        'translate.who_did_it.message3.title': "Dank, zo sluiten ze op elkaar aan. Ik zal ze even achter elkaar zetten.",

        'translate.who_did_it.timer.message1': 'Ik denk dat we moeten kijken naar het aantal kopjes aan het begin en eind van elke video? Kunnen jullie de taken verdelen? Ik zal ook proberen om wat aantekeningen te maken',
        'translate.who_did_it.timer.message2': 'Ik heb wat aantekeningen gemaakt die ik met je kan delen. Nummer 31 eindigt met 1 kopje, maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',
        'translate.who_did_it.timer.message3': 'De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.',

        'translate.who_did_it.hint.explanation': "Er zijn 6 video opnames die in de juiste volgorde moeten worden geplaatst. Kijk zelf goed naar de deurmat en het aantal koffiekopjes aan het begin en einde van elke video. Het is het beste om de taken te verdelen.",
        'translate.who_did_it.hint.hint1': "Nummer 31 is de eerste opname. Deze eindigt met 1 kopje maar zonder deurmat. 98 begint met 2 kopjes zonder deurmat en eindigt met 1 kopje en een deurmat. 22 begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
        'translate.who_did_it_challenging.hint.hint1': "De eerste video start zonder koffiekopjes op het aanrecht en met rechts een deurmat in beeld. Aan het einde van deze video staat er een kopje en is de deurmat weg.",

        'translate.who_did_it.hint.hint2': "De tweede video zou moeten beginnen met 1 kopje en zonder deurmat en eindigen met 2 kopjes en met een deurmat. De derde video is nummer 22. Deze begint met 2 kopjes en een deurmat en eindigt met 3 kopjes en een deurmat.",
        'translate.who_did_it.hint.solution': "De volgorde is 31-84-22-17-98-56. <br> Als je kijkt naar het aantal kopjes op het aanrecht en of er rechts in beeld een deurmat ligt, kun je zien dat het einde van de ene video aansluit op het begin van een andere. Er is maar 1 volgorde mogelijk. 31: begin (geen kopjes en wel een deurmat), einde (1 kopje en geen deurmat).  84 (begin: 1 kopje en geen deurmat), einde (2 kopjes en een deurmat).  22: begin (2 kopjes en een deurmat), einde (3 kopjes en een deurmat). 17: begin (3 kopjes en een deurmat, einde (2 kopjes en geen deurmat). 98: begin (2 kopjes en geen deurmat), einde (1 kopje en wel een deurmat). 56: begin (1 kopje en een deurmat) einde (1 kopje en een deurmat).",

        'translate.enclose.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/7cover.webp",
        'translate.enclose.timer.title': "7. Enclose - A Toxic Case",
        'translate.enclose.timer.subtitle': "De opdracht start over:",

        'translate.enclose.message1.title': "We moeten de bommen onschadelijk maken. Ik heb contact met twee mensen op deze verdieping die ons kunnen helpen. Jennifer, een andere bewaker en Justin, een van de onderzoekers. Ik heb ze alles verteld wat ik weet.",
        'translate.enclose.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Ze start in kamer 270. Kijk of je de kamers op camera kan herkennen en luister goed wat Justin en Jennifer zeggen.",
        'translate.enclose_challenging.message2.title': "Kunnen jullie uitzoeken welke route ze loopt? Als ik het goed heb gehoord komt ze in totaal in 8 ruimtes. We hebben toegang kunnen krijgen tot een beperkt aantal camera's. Als we weten waar die zijn weten we waar ze wel en niet langsloopt.",
        'translate.enclose.message2.error': "Ik weet niet zeker of dit klopt. We hebben maar 1 kans, kunnen jullie het nog een keer controleren? Gebruik de TAG ANTWOORD link hierboven en laat me weten welke route ze neemt. We moeten er zeker van zijn.",
        'translate.enclose.message3.title': "Ik hoop dat het klopt, we moeten nu iets doen! Als Linda 204 binnengaat, kan Justin naar 266 om de bom onschadelijk te maken. Ik wacht zo lang mogelijk en schakel de bom uit in 270. Bekijk eerst de volgende video (8. The End) voordat je op Score klikt.",

        'translate.enclose.timer.message1': 'Ik heb geteld hoe vaak de deuren open en dicht gaan. Ze gaat 8 keer een andere kamer in, waaronder ruimte 270 waar ze start.',
        'translate.enclose.timer.message2': 'Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Hebben jullie haar op de camera gezien in kamer 201?',

        'translate.enclose.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. In totaal is ze in 8 ruimtes, waaronder haar startpunt 270. Probeer eerst uit te zoeken waar Jennifer en Justin (de onderzoeker) zitten. Wanneer horen ze iets en wanneer niet?",
        'translate.enclose_challenging.hint.explanation': "Jullie doel is om de route die Linda neemt in de juiste volgorde aan te geven. Probeer eerst uit te zoeken waar Justin en Jennifer zitten. Wanneer horen ze iets en wanneer niet? En horen ze iets in de kamers die aan hen grenzen? Wat zegt dit over waar ze zitten?",

        'translate.enclose.hint.hint1': "Justin, de onderzoeker, zit in het toilet dat vast zit aan ruimte 281. Hij hoort alleen iets in de kamer naast hem nadat Linda Steve is gepasseerd. In totaal moeten we 8 kamers vinden, inclusief het startpunt 270.",
        'translate.enclose_challenging.hint.hint1': "In totaal zoeken we 8 ruimtes, waaronder haar startpunt 270. Zoek uit in welke ruimtes we toegang hebben tot de camera door te kijken naar de positie van de deuren. Van welke ruimte hebben we toegang tot de camera, maar is ze daar nooit te zien?",
        'translate.enclose.hint.hint2': "Jennifer zegt dat ze niets heeft gehoord, ook niet in de kamers naast haar. Dit betekent dat Linda niet in kamers 222 en 240 is geweest. Ze is niet op camera gezien in 201, dus ze moet door ruimte 217 naar de gang zijn gegaan.",
        'translate.enclose_challenging.hint.hint2': "We hebben toegang tot de camera in kamer 201, maar zien Linda daar nooit verschijnen. Jennifer zit in de opslagruimte rechtsboven op de kaart. Ze heeft niets gehoord, ook niet in de ruimtes naast haar. Justin zit in het toilet wat vastzit aan kamer 281..",

        'translate.enclose.hint.solution': "De route is 270 - 228 - 266 - 204 - 217 - 281 - 247 - 292. <br> Je ziet Linda eerst in een grote ruimte, wat geen kantoor is. Daarna zie je haar even niet (dus is ze niet naar 290 gegaan) en dan weer op camera in een ruimte met ramen waarbij er aan beide kanten deuren naar buiten opengaan. Dit moet ruimte 266 zijn, dus moet ze langs 228 zijn gegaan. Op enig moment daarna is Linda in de gang. Eerst hoort Steve haar en daarna hoort Justin haar pas voor het eerst in de ruimte naast hem. Justin zit dus in het toilet naast 281 en niet naast 270, 228 of 204, want die grenzen aan ruimtes waar ze eerder is geweest. Daarna is ze op camera weer te zien in ruimte 292 (dus moet ze door 247 zijn gegaan) en vanuit daar weer terug waar ze begon (270). Omdat Jennifer niets heeft gehoord in de ruimtes naast haar, is de enige mogelijkheid dat Jennifer in de opslagruimte zat naast 222 and 240. Dit betekent dat Linda door ruimte 217 naar de gang is gegaan. Als ze door 201 was gegaan, was ze gezien op camera.",

        'translate.enclose.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7map.webp",
        'translate.enclose.multimap.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7multimap.webp",
        'translate.enclose.201.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7201.webp",
        'translate.enclose.204.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7204.webp",
        'translate.enclose.210.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7210.webp",
        'translate.enclose.217.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7217.webp",
        'translate.enclose.222.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7222.webp",
        'translate.enclose.266.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7266.webp",
        'translate.enclose.227.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7227.webp",
        'translate.enclose.228.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7228.webp",
        'translate.enclose.235.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7235.webp",
        'translate.enclose.239.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7239.webp",
        'translate.enclose.240.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7240.webp",
        'translate.enclose.241.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7241.webp",
        'translate.enclose.247.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7247.webp",
        'translate.enclose.250.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7250.webp",
        'translate.enclose.265.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7265.webp",
        'translate.enclose.267.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7267.webp",
        'translate.enclose.270.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7270.webp",
        'translate.enclose.271.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7271.webp",
        'translate.enclose.281.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7281.webp",
        'translate.enclose.289.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7289.webp",
        'translate.enclose.292.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7292.webp",
        'translate.enclose.293.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7293.webp",
       
        
        'translate.enclose_challenging.cmapall.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7cmapall.webp",
        'translate.enclose_challenging.mapstorage.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7cmapstorage.webp",
        'translate.enclose_challenging.maptoilet.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/multi/nl7cmaptoilet.webp",


    }
);

export default nlPuzzleToxicCase;
