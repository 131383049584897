import Typography from "@mui/material/Typography";
import {Alert, Button, Stack} from "@mui/material";
import PuzzleSeatList from "../game-seats/PuzzleSeatList";
import InviteLinkBox from "../InviteLinkBox";
import * as React from "react";
import {useRoomContext} from "../../context/RoomContextProvider";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import {useProblemContext} from "../../context/ProblemContextProvider";
import {isEmpty} from "lodash";
import {useTranslation} from "react-i18next";
import {ERROR_ID} from "../../util/constants";
import {useNavigate} from "react-router-dom";

export function WaitingRoomView({onShowInvite}) {
    const {t: tGame} = useTranslation();
    const {t} = useTranslation('puzzleWaitingStartModal');
    const navigate = useNavigate();

    const {
        roomId,
        isHost,
    } = useRoomContext();

    const {
        puzzleId,
        startPuzzleEnabled,
        secondsRemaining,
        isPuzzleStartInProgress,
        requestPuzzleStart,
        puzzleJoinResult,
        isMyMemberKicked,
        puzzleStateResult
    } = usePuzzleContext();

    const {
        setWaitingRoomProblemActive
    } = useProblemContext();

    const {data} = puzzleStateResult;

    const onStartPuzzleClick = isHost
        ? () => requestPuzzleStart({roomId, puzzleId})
        : undefined;

    const shouldShowInviteBox = isHost && !isPuzzleStartInProgress && data
        && (isEmpty(data?.previousPuzzlePlayers) || data?.previousPuzzlePlayers?.length === 1);

    const isKickedError = puzzleJoinResult?.error?.response?.data?.errorId === ERROR_ID.MEMBER_IS_KICKED;

    const getErrorMsg = () => {
        if (puzzleJoinResult?.error?.response?.status === 429) {
            return t('puzzleIsFull');
        }
        if (puzzleJoinResult?.error?.response?.status === 423) {
            return t('puzzleAlreadyStarted');
        }
        if (isKickedError) {
            return t('kickedOut');
        }

        return puzzleJoinResult?.error?.response?.data?.message;
    }

    const onRejoin = () => {
        const errorData = puzzleJoinResult?.error?.response?.data?.errorData;
        const {name, email} = errorData ?? {};
        const state = {memberName: name, memberEmail: email};
        navigate(`/${roomId}/${puzzleId}/guest?rejoin=true`, {state})
    }

    return (
        <>
            {puzzleJoinResult?.isSuccess && (
                <Typography variant="h6" component="h2">
                    {isHost
                        ? t('titleHostPov')
                        : t('titleGuestPov')}
                </Typography>
            )}

            {puzzleJoinResult?.isError && (
                <Stack gap={2}>
                    <Typography variant="h6" component="h2">
                        {t('titleError')}
                    </Typography>
                    <Alert severity="error">
                        {getErrorMsg()}
                    </Alert>
                </Stack>

            )}

            {isKickedError && (
                <Button variant="outlined" onClick={onRejoin}>
                    {tGame('rejoin')}
                </Button>
            )}

            <PuzzleSeatList
                onShowInvite={onShowInvite}
                loading={!data && !puzzleJoinResult?.error}
            />

            {shouldShowInviteBox && (
                <Stack
                    mb={1}
                    sx={{borderTop: '1px solid', borderColor: '#313b50'}}
                    pt={2}
                >
                    <InviteLinkBox/>
                </Stack>
            )}
            {isHost && !isPuzzleStartInProgress && (
                <Stack direction="row" width="100%" gap={2}>
                    <Button
                        variant="outlined"
                        sx={{opacity: 1, paddingX: 4}}
                        onClick={() => setWaitingRoomProblemActive(true)}
                    >
                        {t('problem')}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!startPuzzleEnabled}
                        sx={{opacity: startPuzzleEnabled ? 1 : 0.4}}
                        onClick={onStartPuzzleClick}
                        fullWidth
                    >
                        {t('startGame')}
                    </Button>
                </Stack>
            )}

            {!isHost && !isPuzzleStartInProgress && !isMyMemberKicked && !isKickedError && !puzzleJoinResult?.isError && (
                <Alert severity="warning">
                    {t('waitForStartAlert')}
                </Alert>
            )}

            {isMyMemberKicked && (
                <Alert severity="error">
                    {t('kickedOut')}
                </Alert>
            )}

            {isPuzzleStartInProgress && !puzzleJoinResult.isError && (
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    {t('startingIn', {secondsRemaining})}
                </Typography>
            )}
        </>
    )
}