import {Collapse, ListItem, ListItemButton, ListItemText} from "@mui/material";
import * as React from "react";
import HintModal from "./HintModal";
import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";
import useIsGuest from "../hooks/useIsGuest";
import gameApi from "../api/gameApi";
import { useRecoilState } from "recoil";
import { timerStartedAtState } from "../recoil_state";
import gameApiV2 from "../api/gameApiV2";
import {useRoomContext} from "../context/RoomContextProvider";
import {usePuzzleContext} from "../context/PuzzleContextProvider";

export default function HintItems({ hints, isPuzzleSolved }) {
    const {roomId, myMemberId} = useRoomContext();
    const {puzzleId, finishedAt} = usePuzzleContext();

    const { isGuest } = useIsGuest(roomId);
    const [hint, setHint] = useState(null);
    const [used, setUsed] = useState([]);
    const [disabledHints, setDisabledHints] = useState({});
    const { t } = useTranslation('puzzle');
    const [timerStartedAt] = useRecoilState(timerStartedAtState);

    const [expanded, setExpanded] = useState(false);

    const onToggleExpand = () => {
        setExpanded(prev => !prev);
    };

    useEffect(() => {
        const checkHints = async () => {
            const newDisabledHints = {};
            for (const hint of hints) {
                newDisabledHints[hint.type] = await isHintDisabled(hint.disableAfter, hint.type);
            }
            setDisabledHints(newDisabledHints);
        };
        checkHints();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hints]);

    const onHintClick = (hint, type) => {
        if (isPuzzleSolved) {
            return;
        }
        setHint(hint);
        setUsed(prev => [...prev, type]);
        gameApiV2.getHint(roomId, puzzleId, myMemberId, type).then();
    };

    const updateUsedWithAllPlayerOpenedHintByValue = (hintType) => {
        return gameApi.didAnyPlayerOpenedHint(roomId, puzzleId, isGuest, hintType)
            .then(hintUsageView => {
                return hintUsageView.used;
            });
    };

    const translate = (title) => {
        if (title?.startsWith('translate')) {
            return t(title);
        }
        return title;
    };

    const isHintDisabled = async (disableAfter, hintType) => {
        if (!disableAfter) {
            return false;
        }
        if (!timerStartedAt) {
            return false;
        }

        const currentTime = new Date();
        const startTime = new Date(timerStartedAt);
        const elapsedTime = (currentTime - startTime) / 1000; // Convert milliseconds to seconds
        const timeDisabled = elapsedTime > disableAfter; // true if we should hide hint on that time
        const hintUsedAlready = await updateUsedWithAllPlayerOpenedHintByValue(hintType);
        if (timeDisabled) {
            if (hintUsedAlready) {
                return false;
            }
            return true; // time passed and it was not used
        }
        return false;
    };

    const renderSolution = () => {
        return hints?.filter(h => h.type === 'SOLUTION').map(h =>
            <ListItem key={h?.label + "-" + h?.value}>
                <ListItemButton
                    disabled={isPuzzleSolved || disabledHints[h?.type] || false}
                    onClick={() => onHintClick(h, h?.type)}
                >
                    <ListItemText primary={translate(h?.label)} />
                </ListItemButton>
            </ListItem>
        );
    };

    const hintsList = () => (
        <>
            { hints?.filter(h => h.type !== 'SOLUTION').map(h =>
                <ListItem key={h?.label + "-" + h?.value}>
                    <ListItemButton
                        disabled={isPuzzleSolved || disabledHints[h?.type] || false}
                        onClick={() => onHintClick(h, h?.type)}
                    >
                        <ListItemText primary={translate(h?.label)} />
                    </ListItemButton>
                </ListItem>
            )}
            {renderSolution()}
        </>
    );

    return (
        <>
            <ListItem sx={{ paddingX: 0 }}>
                <ListItemButton onClick={onToggleExpand} disabled={isPuzzleSolved}>
                    <ListItemText primary={t('translate.game.hint.hints.label')} />
                    <KeyboardArrowDownIcon />
                </ListItemButton>
            </ListItem>
            <HintModal hint={hint} onClose={() => setHint(null)} />
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {hintsList()}
            </Collapse>
        </>
    );
}
