import LangInput from "./LangInput";
import * as React from "react";
import {useTranslation} from "react-i18next";
import useSaveLanguageChange from "../../hooks/useSaveLanguageChange";
import {useRoomContext} from "../../context/RoomContextProvider";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import LanguageChangeModal from "./LanguageChangeModal";
import {useState} from "react";
import {useRecoilState} from "recoil";
import {globalErrorState} from "../../recoil_state";

export default function LanguageDropdown() {
    const {i18n} = useTranslation('navbar');
    const {roomId} = useRoomContext();
    const {puzzleId, finishedAt} = usePuzzleContext();
    const saveLanguage = useSaveLanguageChange({roomId, puzzleId})
    const [targetLang, setTargetLang] = useState();
    const [loading, setLoading] = useState(false);
    const [, setError] = useRecoilState(globalErrorState);

    const handleError = (err) => {
        if (err?.response?.data) {
            setError(err?.response?.data)
        }
    }

    const changeAndSaveLanguage = (lang) =>  i18n.changeLanguage(lang).then(saveLanguage)

    const handleLanguageChangeRequested = (event) => {
        if (!finishedAt) {
            setTargetLang(event.target.value);
        } else {
            setLoading(true);
            changeAndSaveLanguage(event.target.value)
                .catch(handleError)
                .finally(() => setLoading(false));
        }
    };

    const handleModalClose = () => {
        setTargetLang(undefined);
    }
    const handleModalConfirm = () => {
        setLoading(true);
        changeAndSaveLanguage(targetLang)
            .then(handleModalClose)
            .then(() => window.location.reload())
            .catch(handleError)
            .finally(() => setLoading(false));
    }

    return (
        <>
            <LanguageChangeModal
                targetLang={targetLang}
                open={!!targetLang}
                onClose={handleModalClose}
                loading={loading}
                onConfirm={handleModalConfirm}
            />
            <LangInput onChange={handleLanguageChangeRequested}/>
        </>
    )
}