import * as React from 'react';
import {useState} from 'react';
import Modal from '@mui/material/Modal';
import {modalStyle} from "../../style/modalStyle";
import {Stack} from "@mui/material";
import Countdown from "react-countdown";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import {useRoomContext} from "../../context/RoomContextProvider";
import PlayerProblemHelp from "./PlayerProblemHelp";
import {useProblemContext} from "../../context/ProblemContextProvider";
import WaitingRoomProblemHelp from "./WaitingRoomProblemHelp";
import WaitingRoomInviteBoxView from "./WaitingRoomInviteBoxView";
import {WaitingRoomView} from "./WaitingRoomView";

export default function PuzzleWaitingStartModal() {
    const [inviteBoxViewActive, setInviteBoxViewActive] = useState(false);
    const {
        myMemberId,
    } = useRoomContext();

    const {
        puzzleStartsAt,
        handleCountdown,
        isPuzzleStarted,
    } = usePuzzleContext();

    const {
        playerProblemHelpActive,
        setPlayerProblemHelpActive,
        waitingRoomProblemActive,
        setWaitingRoomProblemActive
    } = useProblemContext();

    const renderWaitingRoom = () => (
        <WaitingRoomView
            onShowInvite={() => setInviteBoxViewActive(true)}
        />
    )

    const renderPlayerProblemHelp = () => (
        <PlayerProblemHelp
            onClose={() => setPlayerProblemHelpActive(null)}
            player={playerProblemHelpActive}
        />
    )

    const renderWaitingRoomProblemHelp = () => (
        <WaitingRoomProblemHelp
            onClose={() => setWaitingRoomProblemActive(false)}
            active={waitingRoomProblemActive}
        />
    )

    const renderInviteBoxView = () => (
        <WaitingRoomInviteBoxView
            onClose={() => setInviteBoxViewActive(false)}
        />
    )

    return (
        <>
            {!!puzzleStartsAt && <Countdown date={new Date(puzzleStartsAt)} renderer={handleCountdown}/>}
            <Modal open={!!myMemberId && !isPuzzleStarted}>
                <Stack sx={modalStyle} gap={2}>
                    {[
                        {
                            takeIf: !!inviteBoxViewActive,
                            content: renderInviteBoxView(),
                        },
                        {
                            takeIf: !!playerProblemHelpActive,
                            content: renderPlayerProblemHelp(),
                        },
                        {
                            takeIf: !!waitingRoomProblemActive,
                            content: renderWaitingRoomProblemHelp(),
                        },
                        {
                            takeIf: true,
                            content: renderWaitingRoom(),
                        }
                    ].find(it => !!it.takeIf)?.content}
                </Stack>
            </Modal>
        </>
    );
}
