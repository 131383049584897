import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import useJoinPuzzle from "../hooks/useJoinPuzzle";
import useStartPuzzle from "../hooks/useStartPuzzle";
import useCheckPuzzleState from "../hooks/useCheckPuzzleState";
import moment from "moment/moment";
import {useRoomContext} from "./RoomContextProvider";
import {useRecoilState} from "recoil";
import {timerConfigState, timerStartedAtState} from "../recoil_state";
import {isEmpty} from "lodash";

export const PuzzleContext = createContext({
    puzzleId: null,
    roomId: null,
    myMemberId: null,
    isGuest: null,
});

export const usePuzzleContext = () => useContext(PuzzleContext);

const PuzzleContextProvider = ({children}) => {
    const {roomId, myMemberId} = useRoomContext();
    const {puzzleId} = useParams();
    const [isPuzzleStarted, setIsPuzzleStarted] = useState(false);
    const [puzzleStartsAt, setPuzzleStartsAt] = useState(null);
    const [secondsRemaining, setSecondsRemaining] = useState(null);
    const [gameData, setGameData] = useState();
    const [isMyMemberKicked, setIsMyMemberKicked] = useState(false);
    const [difficulty, setDifficulty] = useState(null);
    const [language, setLanguage] = useState(null);

    const [isWaitingForStart, setIsWaitingForStart] = useState(false);
    const [shouldPollState, setShouldPollState] = useState(false);

    const puzzleJoinResult = useJoinPuzzle(roomId, puzzleId, myMemberId);
    const requestPuzzleStartResult = useStartPuzzle(puzzleId);

    const startPollingState = () => setShouldPollState(true);
    const stopPollingState = () => setShouldPollState(false);

    const {
        mutate: requestPuzzleStart,
        isPending: requestPuzzleStartLoading,
        isSuccess: requestPuzzleStartSuccess
    } = requestPuzzleStartResult;

    const [, setTimerConfig] = useRecoilState(timerConfigState);
    const [, setTimerStartedAt] = useRecoilState(timerStartedAtState);

    const isPuzzleJoined = puzzleJoinResult.isSuccess;
    const isPollEnabled = isWaitingForStart || shouldPollState;
    const puzzleStateResult = useCheckPuzzleState(roomId, puzzleId, myMemberId, isPollEnabled);

    const {data: puzzleReadinessData} = puzzleStateResult;

    useEffect(() => {
        const apiDifficulty = puzzleReadinessData?.difficulty;
        if (apiDifficulty) {
            if (!difficulty) {
                setDifficulty(apiDifficulty);
            }
            if (difficulty && apiDifficulty !== difficulty) {
                window.location.reload();
            }
        }
        // eslint-disable-next-line
    }, [puzzleReadinessData?.difficulty])

    useEffect(() => {
        const apiLanguage = puzzleReadinessData?.language;
        if (apiLanguage) {
            if (!language) {
                setLanguage(apiLanguage);
            }
            if (language && apiLanguage !== language) {
                window.location.reload();
            }
        }
        // eslint-disable-next-line
    }, [puzzleReadinessData?.language])

    const isPuzzleStartInProgress = !!puzzleReadinessData?.startedAt
        || requestPuzzleStartLoading
        || requestPuzzleStartSuccess

    const isEveryoneJoinedFromPrevious = () => {
        if (isEmpty(puzzleReadinessData?.previousPuzzlePlayers)) {
            return true;
        }
        const hasPlayerJoined = (player) => {
            return puzzleReadinessData?.players.some(it => it.memberId === player.memberId)
        }
        return puzzleReadinessData?.previousPuzzlePlayers.every(hasPlayerJoined);
    }

    const startPuzzleEnabled = puzzleReadinessData?.players?.length >= 2
        && isEveryoneJoinedFromPrevious()
        && !isPuzzleStartInProgress
        && !isPuzzleStarted;

    useEffect(() => {
        setIsWaitingForStart(isPuzzleJoined && !isPuzzleStarted)
    }, [isPuzzleJoined, isPuzzleStarted])

    useEffect(() => {
        if (puzzleReadinessData?.startedAt) {
            setPuzzleStartsAt(puzzleReadinessData.startedAt)
            setTimerStartedAt(puzzleReadinessData.startedAt) // recoil
            setTimerConfig(puzzleReadinessData?.gameData.puzzle.timer) // recoil
            if (moment(puzzleReadinessData?.startedAt).isBefore(moment().subtract(2, 'seconds'))) {
                setIsPuzzleStarted(true)
            } else {
                stopPollingState();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzleStateResult?.dataUpdatedAt]);



    useEffect(() => {
        const data = puzzleStateResult.data
        const gameData = puzzleStateResult.data?.gameData
        if (data) {
            const myMemberIdMissingInPuzzle = data.players.every(it => it.memberId !== myMemberId);
            const isDifficultyChanged = data.difficulty !== difficulty;
            const isLanguageChanged = data.language !== language

            if (myMemberIdMissingInPuzzle && !isDifficultyChanged && !isLanguageChanged && isMyMemberKicked === false) {
                setIsMyMemberKicked(true);
            }
            if (!myMemberIdMissingInPuzzle && isMyMemberKicked === true) {
                setIsMyMemberKicked(false);
                setDifficulty(data.difficulty);
                setLanguage(data.language);
            }
        }
        if (gameData) {
            setGameData(gameData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puzzleStateResult.data])

    const handleCountdown = ({seconds, completed}) => {
        if (completed && !isPuzzleStarted) {
            setIsPuzzleStarted(true);
        }
        setSecondsRemaining(seconds)
    };

    const players = puzzleStateResult?.data?.players;

    const myName = players
        ?.find(it => it.memberId === myMemberId)?.name;

    const opponentName = players
        ?.filter(it => it.memberId !== myMemberId)
        .map(it => it.name)
        .join(', ');

    const gameplays = gameData?.puzzle.gameplayByMemberId;
    const myGameplay = !!myMemberId && gameData
        ? gameplays?.[myMemberId]
        : null;

    const puzzleContextValues = useMemo(() => (
            {
                puzzleJoinResult,

                puzzleId,
                puzzleStateResult,
                difficulty: puzzleStateResult.data?.difficulty,
                nextDifficulty: puzzleStateResult.data?.nextDifficulty,
                finishedAt: puzzleStateResult.data?.finishedAt,

                gameData,
                setGameData,

                myMemberId,
                myGameplay,

                language,

                startPuzzleEnabled,
                requestPuzzleStart,
                puzzleStartsAt,
                handleCountdown,
                secondsRemaining,
                isPuzzleStartInProgress,
                isPuzzleStarted,

                startPollingState,
                stopPollingState,
                isPollEnabled,
                playerNames: {myName, opponentName},

                isMyMemberKicked,
            }
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            puzzleId,
            startPuzzleEnabled,
            puzzleStartsAt,
            secondsRemaining,
            isPuzzleStartInProgress,
            isPuzzleStarted,
            requestPuzzleStart,
            puzzleStateResult,
            myMemberId,
            gameData,
            isPollEnabled,
            puzzleJoinResult,
            isMyMemberKicked,
            language,
        ]);

    return (
        <PuzzleContext.Provider value={puzzleContextValues}>
            {children}
        </PuzzleContext.Provider>
    )
}

export default PuzzleContextProvider;