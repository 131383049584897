import axios from 'axios';
import i18next from 'i18next';

const axiosConfig = {
  baseURL: `${process.env.REACT_APP_API_URL}`,
};

export const coreClient = axios.create(axiosConfig);

coreClient.interceptors.request.use((config) => {
    // Set the Accept-Language header from the current language in i18next
    config.headers['Accept-Language'] = i18next.language;
    return config;
},
    (error) => {
    return Promise.reject(error);
}
);

coreClient.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 402) {
        window.location.href = '/payment';
      }
      // if (error.response.status === 409) {
      //     window.location.href = `/${error.response?.data?.roomId}`;
      // }
      return Promise.reject(error)
    });
