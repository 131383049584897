import { coreClient } from './config';

const versionPrefix = '/v2/game';

const gameRoomApiV2 = {
    getRoomDashboard: (roomId, memberId) => coreClient
            .get(`${versionPrefix}/rooms/${roomId}`.concat(memberId ? `?memberId=${memberId}` : ''))
            .then(({data}) => data),
    setLang: (roomId, puzzleId, lang) => coreClient
        .post(`${versionPrefix}/rooms/${roomId}/lang`, { lang, puzzleId }),
};
export default gameRoomApiV2;
