import * as React from "react";
import {Button, Stack} from "@mui/material";
import {modalStyle} from "../style/modalStyle";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {useRecoilState} from "recoil";
import {globalErrorContextState, globalErrorState} from "../recoil_state";
import {useNavigate} from "react-router-dom";
import {ERROR_ID} from "../util/constants";
import {useTranslation} from "react-i18next";

const GlobalErrorModalProvider = ({children}) => {
    const [error, setError] = useRecoilState(globalErrorState);
    const [errorContext, setErrorContext] = useRecoilState(globalErrorContextState);
    const navigate = useNavigate();
    const { t } = useTranslation('errorModal');

    const handleClose = () => {
        setError(null);
        setErrorContext(null);
    }

    const resolveError = () => {
        if (!error) {
            return null;
        }
        if (error?.errorId === ERROR_ID.PLAYER_NOT_FOUND) {
            return (
                <>
                    <Typography variant="h6" component="h2">
                        {t('difficultyChanged.title')}
                    </Typography>

                    <Typography variant="body6" sx={{ color: 'white' }}>
                        {t('difficultyChanged.description')}
                    </Typography>

                    <Button variant="contained" onClick={() => navigate(0)}>
                        {t('difficultyChanged.button')}
                    </Button>
                </>
            );
        }

        if (error?.errorId === ERROR_ID.PUZZLE_NOT_STARTED) {
            if (errorContext.difficulty !== error?.errorData?.difficulty) {
                return (
                    <>
                        <Typography variant="h6" component="h2">
                            {t('difficultyChanged.title')}
                        </Typography>

                        <Typography variant="body6" sx={{ color: 'white' }}>
                            {t('difficultyChanged.description')}
                        </Typography>

                        <Button variant="contained" onClick={() => navigate(0)}>
                            {t('difficultyChanged.button')}
                        </Button>
                    </>
                );
            }
            if (errorContext.language !== error?.errorData?.language) {
                return (
                    <>
                        <Typography variant="h6" component="h2">
                            {t('languageChanged.title')}
                        </Typography>

                        <Typography variant="body6" sx={{ color: 'white' }}>
                            {t('languageChanged.description')}
                        </Typography>

                        <Button variant="contained" onClick={() => navigate(0)}>
                            {t('languageChanged.button')}
                        </Button>
                    </>
                );
            }
        }
        if (error?.errorId === ERROR_ID.MEMBER_IS_KICKED) {
            const roomId = error?.errorData?.roomId;
            if (roomId) {
                navigate(`/${roomId}/member-not-found`, { state: { puzzleId: errorContext?.puzzleId }});
            } else {
                navigate('/error');
            }
            setError(null);
            return null;
        }
        return (
            <>
                <Typography variant="h6" component="h2">
                    {t('unknown.title')}
                </Typography>
                <Typography variant="body6" sx={{ color: 'white' }}>
                    {error?.message}
                </Typography>
            </>
        )
    }
    return (
        <>
            <Modal open={!!error} onClose={handleClose}>
                    <Stack sx={modalStyle} gap={2}>
                        {resolveError(error)}
                    </Stack>
            </Modal>
            {children}
        </>
    )
}

export default GlobalErrorModalProvider;