import * as React from 'react';
import {useState} from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {alpha, Box} from "@mui/material";
import {useTranslation} from "react-i18next";
import PuzzleStatsDrawer from "./puzzle-stats/PuzzleStatsDrawer";

export default function PuzzleCard(
    {
        gameName,
        puzzleName,
        cover,
        expiresAt,
        completedIn,
        difficulty,
        playerStats,
        score,
    }
) {
    const [isPuzzleStatsOpen, setIsPuzzleStatsOpen] = useState(false);
    const {t} = useTranslation('puzzleCard')

    const renderButtons = () => {
        return (
            <CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Button
                    ullWidth
                    disabled={completedIn === null}
                    size="medium"
                    variant="outlined"
                    onClick={() => setIsPuzzleStatsOpen(true)}
                >
                    {t('seeStats')}
                </Button>
            </CardActions>
        )
    }

    return (
        <Card sx={{borderRadius: '14px', boxShadow: 'none', backgroundColor: 'transparent', color: 'white'}}>
            <CardMedia
                component="img"
                alt="puzzle cover"
                height="172"
                image={cover}
                sx={{borderRadius: '14px'}}
            />
            <CardContent>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontWeight: 'bold'
                    }}
                >
                    {puzzleName}
                    {/*{renderExpires()}*/}
                </Typography>
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Typography variant="body6" sx={{color: alpha('#fff', 0.6)}}>
                        {gameName}
                    </Typography>
                </Box>
            </CardContent>
            {renderButtons()}

            <PuzzleStatsDrawer
                onOpen={() => setIsPuzzleStatsOpen(true)}
                onClose={() => setIsPuzzleStatsOpen(false)}
                isOpen={isPuzzleStatsOpen}
                playerStats={playerStats}
                difficulty={difficulty}
                completedIn={completedIn}
                score={score}
            />
        </Card>
    )
}
