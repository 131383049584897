import {useTranslation} from "react-i18next";
import gameRoomApiV2 from "../api/gameRoomApiV2";

const useSaveLanguageChange = ({roomId, puzzleId, callback}) => {
    const {i18n} = useTranslation();
    return () => {
        if (callback) {
            gameRoomApiV2.setLang(roomId, puzzleId, i18n.language)
                .then(() => {
                    callback();
                })
                .catch(console.error)
        }
        return gameRoomApiV2.setLang(roomId, puzzleId, i18n.language);
    };
}

export default useSaveLanguageChange;