import {CopyField} from "@eisberg-labs/mui-copy-field";
import * as React from "react";
import {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import Chip from "@mui/material/Chip";
import DoneIcon from '@mui/icons-material/Done';
import {useTranslation} from "react-i18next";

export function DefaultCopyField({ ...otherProps }) {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (copied) {
            const timer = setTimeout(() => {
                setCopied(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [copied]);

    return (
        <Stack position="relative">
            {!!copied && (
                <Chip
                    icon={<DoneIcon sx={{ fontSize: 16 }} />}
                    label={t('copied')}
                    color="success"
                    sx={{ position: 'absolute', right: 0, zIndex: 9999 }}
                />
            )}
            <CopyField
                {...otherProps}
                onCopySuccess={() => setCopied(true)}
            />
        </Stack>
    )
}