import {InputLabel, MenuItem, Select, Stack} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import useIsGuest from "../../hooks/useIsGuest";
import DifficultyPromptModal from "./DifficultyPromptModal";
import {useTranslation} from "react-i18next";
import {DIFFICULTIES_OPTIONS} from "../../game/Game";
import {usePuzzleContext} from "../../context/PuzzleContextProvider";
import useChangeDifficulty from "../../hooks/useChangeDifficulty";
import {useRoomContext} from "../../context/RoomContextProvider";

export default function DifficultyDropdown() {
    const {roomId} = useRoomContext();
    const {isGuest} = useIsGuest(roomId);
    const navigate = useNavigate();
    const {difficulty, nextDifficulty, puzzleId, finishedAt} = usePuzzleContext();
    const [newDifficulty, setNewDifficulty] = useState(null);
    const {t} = useTranslation();
    const {t: tPuzzle} = useTranslation('puzzle');
    const {mutate: changeDifficulty, isSuccess, isPending} = useChangeDifficulty()

    const onConfirm = (confirmedDifficulty) => {
        changeDifficulty({roomId, puzzleId, difficulty: confirmedDifficulty})
    }

    const handleDifficultyChange = (e) => {
        setNewDifficulty(e.target.value);
        if (finishedAt) {
            onConfirm(e.target.value);
        }
    }

    useEffect(() => {
        if (isSuccess && !finishedAt) {
            navigate(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);

    const resolveDifficultyValue = () => {
        if (finishedAt) {
            return newDifficulty || nextDifficulty;
        }
        return newDifficulty || difficulty;
    }


    return (
        <>
            <DifficultyPromptModal
                onClose={() => setNewDifficulty(null)}
                open={!finishedAt && newDifficulty !== null}
                onConfirm={() => onConfirm(newDifficulty)}
                loading={isPending || isSuccess}
                newDifficulty={newDifficulty}
            />
            <Stack sx={{width: '100%'}} gap={1}>
                <InputLabel id="difficulty-label">
                    {t('difficulty')}
                </InputLabel>
                <Select
                    fullWidth
                    id="difficulty"
                    value={resolveDifficultyValue()}
                    label={t('difficulty')}
                    onChange={handleDifficultyChange}
                    disabled={isGuest}
                >
                    {Object.values(DIFFICULTIES_OPTIONS).map(k => [
                        <MenuItem key={k} value={k}>
                            {tPuzzle(`translate.game.${k}`)}
                        </MenuItem>
                    ])}
                </Select>
            </Stack>
        </>
    )
}
