import {Icon, ListItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {isNil} from "lodash";

export default function PuzzleStat({ Icon, children, expanded, ...otherProps }) {
    return (
        <ListItem {...otherProps}>
            <ListItemButton>
                {Icon && (
                    <ListItemIcon>
                        <Icon component={Icon} />
                    </ListItemIcon>
                )}
                {children}
                {!isNil(expanded) && (
                    <IconButton>
                        <Icon
                            component={expanded ? ExpandLess : ExpandMore}
                            sx={{marginTop: 3, color: 'white'}}
                        />
                    </IconButton>
                )}
            </ListItemButton>
        </ListItem>
    )
}