import * as React from 'react';
import {useEffect, useState} from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {Trans, useTranslation} from "react-i18next";
import {Alert, Button, Checkbox, FormControlLabel, Stack, TextField} from "@mui/material";
import {modalStyle} from "../style/modalStyle";
import {useRoomContext} from "../context/RoomContextProvider";
import {Link, useLocation} from "react-router-dom";

export default function JoinNewMemberModal({hostName}) {
    const {joinRoomAsGuest, isGuest, myMemberId, joinRoomError} = useRoomContext();
    const {t} = useTranslation('joinNewMemberModal');
    const location = useLocation();
    const { memberName, memberEmail } = location.state ?? {};
    const [name, setName] = useState(memberName ?? '');
    const [email, setEmail] = useState(memberEmail ?? '');
    const [checked, setChecked] = useState(false)
    const handleTermsConditionsChange = (event) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        if (location.state) {
            if (location.state.memberName) {
                setName(location.state.memberName);
            }
            if (location.state.memberEmail) {
                setEmail(location.state.memberEmail);
            }
            setChecked(true);
        }
    }, [location.state]);

    const [emailError, setEmailError] = useState(false);
    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onEmailChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (emailRegex.test(inputEmail)) {
            setEmailError(false);
        } else {
            setEmailError(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!emailError && email && name) {
            joinRoomAsGuest({name, email});
        }
    }

    const resolveError = (error) => {
        if (error?.response?.status === 429) {
            return t('errorFullRoom')
        }
        return error?.message
    }

    return (
        <Modal open={isGuest && !myMemberId}>
            <form onSubmit={handleSubmit}>
                <Stack sx={modalStyle} gap={2}>
                    <Typography variant="h6" component="h2">
                        {t('title', {name: hostName})}
                    </Typography>
                    <TextField
                        onChange={onNameChange}
                        label={t('input.name.label')}
                        value={name}
                        variant="outlined"
                        required
                    />
                    <TextField
                        onChange={onEmailChange}
                        label={t('input.email.label')}
                        value={email}
                        variant="outlined"
                        required
                        error={emailError}
                        helperText={emailError ? t('input.email.error') : ""}
                    />
                    <FormControlLabel
                        label={
                            <Trans
                                i18nKey={t('termsConditionsLabel')}
                                components={{
                                    Link: <Link
                                        to={'/terms-and-conditions'}
                                        target="_blank"
                                        style={{textDecoration: "underline", color: 'white'}}
                                    />
                                }}
                            />
                        }
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={handleTermsConditionsChange}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: 'white',
                                    },
                                }}
                            />
                        }
                    />

                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!name || !email || emailError || !checked}
                        sx={{marginTop: 1}}
                    >
                        {t('submitButtonText')}
                    </Button>
                    {joinRoomError && (
                        <Alert severity="warning">
                            {resolveError(joinRoomError)}
                        </Alert>
                    )}
                </Stack>
            </form>
        </Modal>
    );
}
