const enPuzzleParadise = (
    {
        'translate.game.paradise.instructions.instruction1': `
           <strong> How to play</strong> <br/>
            <p>A Case in Paradise is a cooperative escape room about a valuable statue that has been stolen. To play, each player must have their own smartphone.
            <br/>Each player will receive unique information about the case and a question to answer. Together, you watch a video about the case and work as a team to solve the challenge.
            <br/>Each video begins with an introduction that is part of the story, followed by details relevant to your question. The information is repeated several times, so you won't need to restart the video.
        `,
        'translate.game.paradise.instructions.instruction2': `
         <strong> Rules and options</strong> <br/>
            <br/>When you start a puzzle, a timer begins. You can try to solve the challenge as quickly as possible to earn the highest score, or you can disregard the score and take your time.
            <br/>If you’re having trouble with a challenge, you can ask for a hint. Each challenge includes an explanation hint that provides more details on what to do, as well as two additional hints to help with the puzzle.
            <br/>If the hints aren't enough to solve the puzzle, you can click the Solution option to reveal the answer.
            <br/>Your score is based on the time you take, incorrect answers, and which hints you used. It doesn’t matter if multiple people view the same hint.
            <br/>If you encounter any problems, please have the main player select Problem? in the main menu. This will reset the challenge.
        `,
        'translate.game.paradise.instructions.instruction3': `
        <strong> Video setup</strong> <br/>
        <p>You can watch the videos in two ways: on Vimeo (recommended) or YouTube. We suggest watching them on your TV, though any screen will work.</p>
            <p>For Vimeo, we suggest using another device if you have one. Go to vimeo.com/showcase/11375804, enter password 8712 and click Start Watching and stream it to your television.</p>
            <p>For Youtube, we suggest using another device if you have one. You can enter the following link in your browser: https://shorturl.at/coWw1 </p>
            
         `,
        'translate.game.paradise.instructions.btnText': 'Continue',

        'translate.game.paradise.puzzle_name.intro1': "Intro 1",
        'translate.game.paradise.puzzle_name.intro2': "Intro 2",
        'translate.game.paradise.puzzle_name.the_knight': "The Knight",
        'translate.game.paradise.puzzle_name.the_blackout': "The Blackout",
        'translate.game.paradise.puzzle_name.the_witnesses': "The Witnesses",
        'translate.game.paradise.puzzle_name.the_key': "The Key",
        'translate.game.paradise.puzzle_name.the_interrogation': "The Interrogation",
        'translate.game.paradise.puzzle_name.the_card_game': "The Card Game",
        'translate.game.paradise.puzzle_name.the_traces': "The Traces",
        'translate.game.paradise.puzzle_name.the_security_tapes': "The Tapes",
        'translate.game.paradise.puzzle_name.the_recap': "The Recap",
        'translate.game.paradise.puzzle_name.whodunnit': "Whodunnit",
        'translate.game.paradise.puzzle_name.the_confrontation': "The End",

        'translate.game.paradise.video_after.intro1': 'Is everybody ready? Then start the next video - Intro 2 - and press Continue!',
        'translate.game.paradise.video_after.intro2': 'Is everybody ready to start the game? Then start the next video - 1. The Knight - and press Continue!',
        'translate.game.paradise.video_after.the_blackout': 'Are you ready for the next challenge? Then start the next video  - 3. The Witnesses - and press Continue!',
        'translate.game.paradise.video_after.the_traces': 'Are you ready for the next challenge? Then start the next video - 8. The Tapes - and press Continue!',
        'translate.game.paradise.video_after.the_key': 'Are you ready for the next challenge? Then start the next video -  5. The Interrogation - and press Continue!',
        'translate.game.paradise.video_after.whodunnit': 'Well done, you solved all the puzzles together! Start the next video 11. the End - and press Continue!',
        'translate.game.paradise.video_after.the_missing_items': 'Are you ready for the next challenge? Then start the next video -  8. The Traces - and press Continue!',
        'translate.game.paradise.video_after.the_knight': 'Are you ready for the next challenge? Then start the next video  - 2. The Blackout - and press Continue. ',
        'translate.game.paradise.video_after.the_witnesses': 'Are you ready for the next challenge? Then start the next video  - 4. The Key - and press Continue!',
        'translate.game.paradise.video_after.the_interrogation': 'Are you both ready for the next challenge? Then start the next video  - 6. The Card Game - and press Continue!',
        'translate.game.paradise.video_after.the_security_tapes': 'Are you ready for the next challenge? Then start the next video - 9. The Recap - and press Continue!',
        'translate.game.paradise.video_after.the_card_game': 'Are you ready for the next challenge? Then start the next video  - 7. The Traces - and press Continue!',
        'translate.game.paradise.video_after.the_recap': 'Are you ready for the next challenge? Then start the next video  - 10. Whodunnit - and press Continue!',
        'translate.game.paradise.video_after.the_confrontation': 'Thank you for playing!',

        'translate.paradise.1_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/coverintro1.webp",

        'translate.paradise.1_intro.2p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that both players have a pen and paper.  You will learn the game by playing two quick intro challenges with {{partnerName}}. First, open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.paradise.1_intro.345p.message.title': "Hi {{playerName}}! Welcome to Couch Clues! Before you start, make sure that each player has a pen and paper.  You will learn the game by playing two quick intro challenges with your fellow players. First, open the menu on the top right of this screen, read the instructions and set up the video.",
        'translate.paradise.1_intro.message2.title': "Have you set up the video? Then select ENG subtitles and start the Playlist A Case in Paradise. Follow the instructions on the Intro 1 video (Explanation is under the Hints section). Your goal is to send me a 6 digit code.",
        'translate.paradise.1_intro.message2.errors': "That is not the right answer, but don't worry, this is just practice. Use the same TAG IMAGE link one of the previous messages to try again. Are you stuck? Then check if you found the Playlist 'A Case in Paradise' and that you are watching Intro 1. If so, then open the menu on the top right, open Hints, then Explanation, then hint 1 and then hint 2.",
        'translate.paradise.1_intro.message3.title': "Well done! Now you can pause the video and click the results button below. The result page will tell which video to play next. Your score is determined by the time, hints and wrong answers. If someone uses a hint, all players can look at it and it will count only once.",

        'translate.paradise.1_intro.hint.explanation': "Every puzzle has an explanation. An explanation will explain the puzzle if you have no idea what to do. For this intro 1 challenge, you have to find the same 6 digit number. To find it, all should click hint 1.",
        'translate.paradise.1_intro.23p.player.hint.hint1': "The first two digits of the code are 96. Make sure to share this information. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.2p.guest01.hint.hint1': "The last two digits are 32. Make sure to share this with your partner. This game is all about talking to each other! Now check hint 2.",
        'translate.paradise.1_intro.345p.guest01.hint.hint1': "The 5th digit is a 3. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.345p.guest02.hint.hint1': "The last digit is a 2. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.45p.player.hint.hint1': "The first digit is a 9. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.45p.guest03.hint.hint1': "The second digit is a 6. Make sure to share this with your partners. Communication is key in this game! Now check hint 2.",
        'translate.paradise.1_intro.5p.guest04.hint.hint1': "The third digit is a 1. Make sure to share this with your partner. Communication is key in this game! Now check hint 2.",

        'translate.paradise.1_intro.2p.hint.hint2': "Did your partner tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.paradise.1_intro.34p.hint.hint2': "Did your partners tell you the other numbers? The middle digits are 11. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",
        'translate.paradise.1_intro.5p.hint.hint2': "Did your partners tell you the other numbers? The fourth digit is 1. You can now solve the puzzle, or check the solution to be sure. If you choose to view the solution in the actual game, you won't earn any points for that challenge.",

        'translate.paradise.1_intro.234p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first two digits (96) and the last two digits (32). The second hint showed the same information for all, which is that the middle digits were 11.",
        'translate.paradise.1_intro.5p.hint.solution': "The solution is 961132. <br> Each player received a different first hint showing part of the code. If you shared all the information you would have gotten the first three digits (961) and the last two digits (32). The second hint showed the same information for all, which is that the fourth is a 1.",

        'translate.paradise.2_intro.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/coverintro2.webp",

        'translate.paradise.2_intro.timer.title': "Intro 2",
        'translate.paradise.2_intro.timer.subtitle': "You will receive more information in:",
        'translate.paradise.2_intro.message1.title': "Now it's time for a real practice puzzle. You will receive some images below, try to zoom in on them by tapping on the image and then pinching your fingers.",
        'translate.paradise.2_intro.message2.title': "Look at the Intro 2 video. Your goal is to find a five digit code.",
        'translate.paradise.2_intro.message2.error': "That is not the right code. To try again you have to use the TAG ANSWERS link in the message above to select a different answer.",
        'translate.paradise.2_intro.message3.title': "That's right! You will now continue to the real game. Have fun together!",

        'translate.paradise.2_intro.timer.message1': 'During the game you sometimes get an additional message like this one. These messages contain information from the detective that can help you. If you do not want to receive these messages, the main player can turn them off in the menu.',

        'translate.paradise.2_intro.hint.explanation': "Your goal is to find a 5 digit number. You will have to tell each other what you see on your phone and match that with what you see on television. Select the labels in the right order and press submit.",
        'translate.paradise.2_intro.hint.hint1': "The first digit is 5.",
        'translate.paradise.2_intro.hint.hint2': "The order on the television is Yellow, Red, Blue, Green, Grey. Which numbers match these colours?",
        'translate.paradise.2_intro.hint.solution': "The solution is Five, Nine, Seven, One, Four. You will have to select the labels in that order. <br> The order of the colours on the television is Yellow, Red, Blue, Green,Grey. The yellow number is a 5, the red number is a 9, the blue number a 7, the green number a 1 and the grey number a 4.",

        'translate.paradise.2_intro.four.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/intro2four.webp",
        'translate.paradise.2_intro.five.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/intro2five.webp",
        'translate.paradise.2_intro.seven.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/intro2seven.webp",
        'translate.paradise.2_intro.one.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/intro2one.webp",
        'translate.paradise.2_intro.nine.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/intro2nine.webp",

        'translate.paradise.2_intro.option1': "One",
        'translate.paradise.2_intro.option2': "Two",
        'translate.paradise.2_intro.option3': "Three",
        'translate.paradise.2_intro.option4': "Four",
        'translate.paradise.2_intro.option5': "Five",
        'translate.paradise.2_intro.option6': "Six",
        'translate.paradise.2_intro.option7': "Seven",
        'translate.paradise.2_intro.option8': "Eight",
        'translate.paradise.2_intro.option9': "Nine",
        'translate.paradise.2_intro.option10': "Zero",

        'translate.the_knight.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover1.webp",

        'translate.the_knight.timer.title': "1. The Knight - A Case In Paradise",
        'translate.the_knight.timer.subtitle': "You will receive more information in:",
        'translate.the_knight.message1.title': "Let's figure out what happened here. We need to compare how this room looks now with the camera footage from yesterday. I'll send each of you a unique list of items.",
        'translate.the_knight.2p.message2.title': "As I walk through the room, watch carefully for anything on your list that might have been moved. Each of you has different item numbers, so you need to share those numbers with {{partnerName}}. Once you’ve compared notes, send all the item numbers to me.",
        'translate.the_knight.345p.message2.title': "As I walk through the room, watch carefully for anything on your list that might have been moved. Each of you has different item numbers, so you need to share those numbers with your partners. Once you’ve compared notes, send all the item numbers to me.",
        'translate.the_knight.message2.error': "That doesn't match what I'm seeing here. Let's take another look. I think five items have been moved. Use the same 'Click to Answer' link as before to select different items.",
        'translate.the_knight.message3.title': "Thanks. Let me see if I can piece together what really happened here..",
        'translate.the_knight.timer.message1': 'There are five items that have been moved.',
        'translate.the_knight.timer.message2': 'The Living Room 2 camera shows two items that have been moved. The Living Room 3 camera shows one item that has been moved. The Living Room 4 camera shows two items that have been moved.',
        'translate.the_knight.hint.explanation': "Compare the security camera footage with the footage of Max moving around the room. There are four areas to check. Look closely to see if anything has changed, and if so, identify the right item. Each player needs to give the same answer, so make sure you share the numbers of your items. You can also help them figure out if anything has changed on their end.",
        'translate.the_knight.hint.hint1': "There are five items that have been moved.",
        'translate.the_knight.hint.hint2': "The Living Room 2 camera shows two items that have been moved. The Living Room 3 camera shows one item that has been moved. The Living Room 4 camera shows two items that have been moved.",
        'translate.the_knight.hint.solution': "16,70,34,17,97. Five items have been moved, bowl, candle holder, vase, book and rug. On Living Room 2, you can see that the bowl has fallen on the floor and that the candle holder has been turned around. On the Living Room 3 with the chessboard, you can see the top of the vase has been moved to a different leg of the table. On Living Room 4, you see the book is lying flat instead of standing upright and the rug has been moved.",

        'translate.the_knight.ACIP1list2-m.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP1list2-m.webp",
        'translate.the_knight.ACIP1list2-g.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP1list2-g.webp",


        'translate.the_blackout.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover2.webp",

        'translate.the_blackout.timer.title': "2. The Blackout - A Case in Paradise",
        'translate.the_blackout.timer.subtitle': "You will receive more information in:",
        'translate.the_blackout.message1.title': "So the blackout disabled the power to the alarm… It appears that if all the lights and fans are on, it causes a blackout.",
        'translate.the_blackout.2p.player.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. {{playerName}}, you focus on the lights, {{partnerName}}, you focus on the fans.",
        'translate.the_blackout.2p.guest.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on. {{playerName}}, you focus on the fans, {{partnerName}}, you focus on the lights.",
        'translate.the_blackout.345p.message2.title': "Let’s see if we can recreate this blackout. All lights and fans are turned off now, so let me know what the easiest way is to turn them all on.",

        'translate.the_blackout.message2.error': "I think we need to check again. I suspect only three actions are needed to create a blackout. Can you try it one more time, using the same 'Click to Answer' link as before?",
        'translate.the_blackout.message3.title': "That makes sense. Now let's see if it actually causes a blackout.",
        'translate.the_blackout.timer.message1': 'There are only 3 actions needed to recreate this blackout.',
        'translate.the_blackout.timer.message2': 'You only need to use two of the remotes and one switch to cause a blackout.',
        'translate.the_blackout.hint.explanation': "There are three rooms (bar, lobby, hallway) and there is a light switch and a remote in each room. That means there are 6 possible actions in total. Play close attention to the effects of each action, some of them will be in different rooms. You need to turn on all the lights and fans to create the blackout. Some lights and fans can only be turned by one specific actions, so focus on those first.",
        'translate.the_blackout.hint.hint1': "There are only 3 actions needed to recreate this blackout.",
        'translate.the_blackout.hint.hint2': "You only need to use two of the remotes and one switch to cause a blackout.",
        'translate.the_blackout.hint.solution': "You can create a blackout using the hallway switch, the lobby remote and the bar remote. Using the lobby remote is the only way to turn on the hallway fan, but it also turns on the lobby fan. Once the lobby fan is running, you cannot use the hallway remote or the bar switch (either one would switch the lobby fan back off). That leaves you with only one way to turn on the bar fan: the hallway switch. After that, you’ll still need to turn on the lobby light and bar light, which can only be done with the bar remote. The overview of all effects is this: Bar Fan and Hall Light (Hallway switch). Bar Fan and Lobby Fan (Hallway remote). Bar Light and Hall Light (Lobby switch). Bar Light and Lobby Fan (Bar switch). Bar Light and Lobby Light (Bar remote). Lobby Fan and Hall Fan (Lobby remote).",

        'translate.the_blackout.ACIP2fan.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP2fan.webp",
        'translate.the_blackout.ACIP2light.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP2light.webp",

        'translate.the_witnesses.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover3.webp",

        'translate.the_witnesses.timer.title': "3. The Witnesses - A Case in Paradise",
        'translate.the_witnesses.timer.subtitle': "You will receive more information in:",
        'translate.the_witnesses.message1.title': "These witnesses claim they were in their rooms when the blackout happened. Let’s see if their stories check out.",
        'translate.the_witnesses.message2.title': "Can you place each witness in a specific room at the moment of the blackout?",
        'translate.the_witnesses.message2.error': "That doesn’t quite match their statements. Please check it again using the same 'Click to Answer' link.",
        'translate.the_witnesses.message3.title': "That makes sense. This means that each of these witnesses had an alibi during the blackout. Let’s meet the other guests that were present that night.",
        'translate.the_witnesses.timer.message1': 'Daniel is in room 201.',
        'translate.the_witnesses.timer.message2': 'Justin is in room 202. ',
        'translate.the_witnesses.hint.explanation': "You need to place each guest in the right room. Start by figuring out which people are on the top or bottom floor.",
        'translate.the_witnesses.hint.hint1': "Daniel is in room 201.",
        'translate.the_witnesses.hint.hint2': "Justin is in room 202.",
        'translate.the_witnesses.hint.solution': "Thomas (102) - Gabrielle (204) - Daniel (201) - Liz (104) - Jane (103) - Justin (202). Daniel (201) says that there was noise in his room, but that he heard no one below him and Gabrielle didn’t hear anything below her. This places them both on the top floor, but not next to each other. Liz hears an opera singer, so she is below Gabrielle. Thomas makes noise and hears noise above him, so he is on the bottom floor, but not below Daniel or Gabrielle (they would have heard him otherwise). Jane hears nothing in the room above so she must be in 103. This leaves Justin as the person who made noise and was in the room above Thomas. Gabrielle didn’t hear anything next to her, so so must be in 204 and therefore Liz is in 104. Thomas is next to Jane and therefore in 102. This means Justin is in 202 and that leaves Daniel in 201.",

        'translate.the_witnesses.ACIP3Jane101.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP3Jane101.webp",
        'translate.the_witnesses.ACIP3Justin203.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP3Justin203.webp",
        'translate.the_witnesses.ACIP3Jane.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP3Jane.webp",
        'translate.the_witnesses.ACIP3Justin.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP3Justin.webp",
        'translate.the_witnesses.ACIP3rooms.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP3rooms.webp",
        'translate.the_witnesses.gap_fill_text': `Thomas is staying in room <gap id="1" />. <br /> Gabrielle is staying in room <gap id="2" />. <br /> Daniel is staying in room <gap id="3" />. <br /> Liz is staying in room <gap id="4" />. <br /> Jane is staying in room <gap id="5" />. <br /> Justin is staying in room <gap id="6" />.`,


        'translate.the_key.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover4.webp",

        'translate.the_key.timer.title': "4. The Key - A Case in Paradise",
        'translate.the_key.timer.subtitle': "You will receive more information in:",
        'translate.the_key.message1.title': "Now we know why the door was open, someone grabbed the bottle opener with the spare key inside. Let’s see what the owner remembers about that night.",
        'translate.the_key.message2.title': "Who took possession of the key, and in what order?",
        'translate.the_key.message2.error': "I think it happened differently. From what he says, I think not everyone took the key. Use the previous 'Click to Answer' link and let me know.",
        'translate.the_key.message3.title': "That matches his story. So the Lady took it... Let’s hear what she has to say.",
        'translate.the_key.timer.message1': 'The Boy is the only one who never took the bottle opener.',
        'translate.the_key.timer.message2': 'The Lady took the key twice.',
        'translate.the_key.hint.explanation': "You need to look closely at the video. Focus on when someone takes possession of the key and see what happens next.",
        'translate.the_key.hint.hint1': "The lady took the bottle opener twice.",
        'translate.the_key.hint.hint2': "The Boy is the only one who never had the key.",
        'translate.the_key.hint.solution': "Lady -  Caregiver - Baron - Peach - Lady. In the beginning of the scene you see the lady trying to open the bottle of the Boy. She messes up and leaves the key on the counter for the Caregiver to take it. You see the baron move towards the hands of the  Caregiver and then later you see Peach having the opener. Peach did not take it from the  Caregiver, so she would have taken it from the Baron. After Peach drops the opener, both Peach and the Lady drop to the floor, after which Peach shows empty hands.",

        'translate.the_key.characterlist.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcharacterlist.webp",
        'translate.the_key.gap_fill_text': `<gap id="1" /> first took the key. Then <gap id="2" /> took it. The third person who took the key was <gap id="3" />. Then <gap id="4" /> took the key. In the end, the key ended up with <gap id="5" />.`,


        'translate.the_interrogation.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover5.webp",

        'translate.the_interrogation.timer.title': "5. The Interrogation - A Case in Paradise",
        'translate.the_interrogation.timer.subtitle': "You will receive more information in:",
        'translate.the_interrogation.message1.title': "So the Lady took the spare key. But why? Lets see if she is willing to tell us the truth. {{playerName}}, I will send you information about signs that indicate that she is lying.",
        'translate.the_interrogation.2p.message2.title': "If you focus on these two signs, {{partnerName}} will focus on the other signs. Let me know what she is lying about.",
        'translate.the_interrogation.345p.message2.title': "If you focus on this, your partners will focus on the other signs. Let me know what she is lying about.",
        'translate.the_interrogation.message2.error': "I believe that’s not the full story. I think there are five things she is lying about.",
        'translate.the_interrogation.message3.title': "This would mean she is not the thief, but she definitely knows more. Let’s see what she remembers about this card game.",
        'translate.the_interrogation.timer.message1': 'There are five things that she lies about.',
        'translate.the_interrogation.timer.message2': 'She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.',
        'translate.the_interrogation.hint.explanation': "You need to select truth or lie for each statment that the Lady makes. To determine if she is lying you need to look out for the signs that each player have been given. Only if she uses one of these signs she is lying.",
        'translate.the_interrogation.hint.hint1': "There are five things that she lies about.",
        'translate.the_interrogation.hint.hint2': "She touches her hair twice before answering, one time she looks up, one time she repeats the question and one time she touches her shoulder.",
        'translate.the_interrogation.hint.solution': "ACFHI. There are five lies in total.  The first is that she doesn’t know what Max is talking about (she shortly touches her hair before answering). The second lie is that she just wanted to have a peek because she was just curious (she touches her shoulder). She third lie is that she caused the blackout by accident (she repeats the question). The fourth lie is that she has no motive to steal it (she quickly looks up). The final lie is that  she doesn’t know any of the other guests (she touches her hair).",

        'translate.the_interrogation.list.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP5list.webp",
        'translate.the_interrogation.hairrepeat.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP5hairrepeat.webp",
        'translate.the_interrogation.lookingupshoulder.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP5lookingupshoulder.webp",
        'translate.the_interrogation.gap_fill_text': `She doesn’t know what Max is talking about (<gap id="1"/>). She stole the key but she did not steal the Knight (<gap id="2" />). She just wanted to have a peek (<gap id="3" />). She got scared and hid into a closet (<gap id="4" />). Someone came inside. She didn’t know who it was (<gap id="5" />). She caused the blackout by accident (<gap id="6" />). She has no interest in the value of the Knight (<gap id="7" />). She has no motive to steal the Knight  (<gap id="8" />). She doesn’t know any of the other guests (<gap id="9" />). She felt she was cheated during a card game (<gap id="10" />).`,

        'translate.the_card_game.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover6.webp",

        'translate.the_card_game.timer.title': "6. The Card Game - A Case in Paradise",
        'translate.the_card_game.timer.subtitle': "You will receive more information in:",
        'translate.the_card_game.message1.title': "So the Lady says she was cheated. I think I recognize the game she is describing. I will send you the rules. If you are unfamiliar with these types of games I have included a general explanation as well. Let’s see if we can figure out how this game was played.",
        'translate.the_card_game.2p.player.message2.title': "{{playerName}}, you play as the Boy and {{partnerName}}, you play as Peach. Let me know which cards were played in order for one person to win all 5 rounds. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
        'translate.the_card_game.2p.guest.message2.title': "{{playerName}}, you play as Peach and {{partnerName}}, you play as the Boy. Let me know which cards were played in order for one person to win all 5 rounds. If they were cheating we have to assume that the Boy and Peach knew each others cards, so feel free to communicate which cards you have.",
        'translate.the_card_game.345p.guest.message2.title': "NOG EDIT.",

        'translate.the_card_game.message2.error': "That’s not right. They might have cheated with the cards, but the Lady said they did play by the rules. Only one person won all five rounds. Use the link in the previous message to try again.",
        'translate.the_card_game.message3.title': "So that's how Peach won all five rounds. Classic. Lets hear what she has to say about this.",
        'translate.the_card_game.timer.message1': 'Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.',
        'translate.the_card_game.timer.message2': 'The second card that is played by the dealer is a B (Jack) of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.',
        'translate.the_card_game.hint.explanation': "You need to play the cards in an order that one player will win all five rounds. If you are unfamiliar with card games in general, please check the card rules first.",
        'translate.the_card_game.hint.hint1': "Only Peach has a Spades card, so she is the only one who can win all rounds. The Boy needs to find a way to lose some of his best cards so Peach can win each round. Think about which cards remain after you play each round.",
        'translate.the_card_game.hint.hint2': "The second card that is played by the dealer is a B (Jack) of Spades. The boy does not have any Spades, so he can play any card he wants. If he plays his Ace of Hearts, he can make sure he does not win with that card on a later turn.",
        'translate.the_card_game.hint.solution': "The Boy played 8 Hearts - A Hearts - B Hearts - K Clubs - B Clubs. Peach played 10 hearts - V spades - K Hearts - 7 Hearts - V Clubs. The Lady plays a Jack (B) of Spades, so the only person who can will all five rounds is Peach. Peach has a Queen of Spades and the Boy has no Spades. Peach needs to win the first round with a 10 of Hearts, the second with a Queen (V) of Spades, the third with a King of Hearts, the fourth with a 7 of Hearts and the fifth with a Queen (V) of Clubs. The first card the Boy can play is only the 8 of Hearts, to stay below the 10 of hearts of Peach. The Boy has to play a Hearts, because Hearts is played by the dealer. On the second turn, because The boy does not have any Spades, he can now dump one of his best cards. He needs to play his Ace of Hearts, because if he keeps his Ace of Hearts he will win the round on the third of fourth card. The third round he now has only one Heart remaining, so he must play Jack (B) of Hearts. The fourth round he does not have any Hearts, so he can use this turn to dump his King of Clubs. The last turn he only has a Jack of Clubs which loses to Peaches Queen (V) of Clubs.",

        'translate.the_card_game.rules.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6rules.webp",
        'translate.the_card_game.Bclubsc.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6Bclubsc.webp",
        'translate.the_card_game.Kclubsd.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6Kclubsd.webp",
        'translate.the_card_game.8heartse.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP68heartse.webp",
        'translate.the_card_game.Bheartsf.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6Bheartsf.webp",
        'translate.the_card_game.Aheartsg.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6Aheartsg.webp",
        'translate.the_card_game.7heartsl.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP67heartsl.webp",
        'translate.the_card_game.10heartsm.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP610heartsm.webp",
        'translate.the_card_game.Kheartsn.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6Kheartsn.webp",
        'translate.the_card_game.Vclubso.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6Vclubso.webp",
        'translate.the_card_game.Vspadesp.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6Vspadesp.webp",
        'translate.the_card_game.explanation1.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6explanation1.webp",
        'translate.the_card_game.explanation2.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP6explanation2.webp",

        'translate.the_traces.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover7.webp",
        'translate.the_traces.timer.title': "7. Traces - A Case in Paradise",
        'translate.the_traces.timer.subtitle': "You will receive more information in:",
        'translate.the_traces.message1.title': "Help me out here. I really want to catch this guy! ",
        'translate.the_traces.message2.title': "Let’s see if we can figure out where he went. Focus on the traces that he left.",
        'translate.the_traces.message2.error': "That doesn’t completely matches the traces he left. I think there were four things that happened during his flight.",
        'translate.the_traces.message3.title': "That means he ended up with on shoe in the attic. Let’s see if we can find him there",
        'translate.the_traces.timer.message1': 'There are four things that happened during his flight, each causing a change in the  traces he left. First he knocked over a vase with dried flowers. I know he still had 2 shoes on in hallway 241-249.',
        'translate.the_traces.timer.message2': 'I beleive during his flight four things happened, but not in this order; he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. The dried flowers stuck to his jacket and fell off until he dropped his jacket.',
        'translate.the_traces.hint.explanation': "You need to figure out in which order the Boy passed through the floors. On some floors something happened that changed his traces. First try to figure out what happened during his flight. Then focus on how the traces change after each thing that happen to determine the right order.",
        'translate.the_traces.hint.hint1': "There are four things that happened during his flight, each causing a change in the  traces he left. First he knocked over a vase with dried flowers. He still had 2 shoes on in hallway 241-249.",
        'translate.the_traces.hint.hint2': "During his flight four things happened (not in this order); he loses his jacket, knocks over a vase, loses one shoe and steps into a bucket of water. The dried flowers stuck to his jacket and fell off until he dropped his jacket.",
        'translate.the_traces.hint.solution': "(261-269). (151-159). Attic (bucket). (251-259). (241-249). (141-149). Attic (storage). The first thing he does is that he knocks over a vase. You can see traces of the dried flowers near the bucket in the attic, so any halls with wet footsteps had to come after this moment and the knocking over the vase happened before. Near room 252 you see dried flowers and wet footsteps until he loses his jacket, after which only the wet footprints remain. Near room 243 you only see wet footprints. Near room 142 you see wet footprints and one shoe, after which the traces show only one foot instead of two. On attic A  you only see one trace of one foot, which leaves this as the place that he is hiding.",

           
        'translate.the_security_tapes.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover8.webp",
        'translate.the_security_tapes.timer.title': "8. The Tapes- A Case in Paradise",
        'translate.the_security_tapes.timer.subtitle': "You will receive more information in:",
        'translate.the_security_tapes.message1.title': "So Peach wanted him to delete the evidence. Was that just for cheating at the card game or also for stealing the Knight? I uploaded the camera recordings right before the blackout.",
        'translate.the_security_tapes.2p.player.message2.title': "We need to figure out the movements of all the suspects before the blackout. {{playerName}}, focus on the Caregiver, Peach and the Owner. {{partnerName}} will focus on the other three suspects.",
        'translate.the_security_tapes.345p.player.message2.title': "NOG EDIT.",
        'translate.the_security_tapes.2p.guest.message2.title': "We need to figure out the movements of all the suspects before the blackout. {{playerName}}, focus on the Boy, the Lady and the Baron. {{partnerName}} will focus on the other three suspects.",
        'translate.the_security_tapes.345p.guest.message2.title': "NOG EDIT.",
           
        'translate.the_security_tapes.message2.error': "That doesn’t match what I’m seeing here. Can you check again? (use the previous 'Click to Answer' link)",
        'translate.the_security_tapes.message3.title': "So this is where they all were right before the blackout. Let’s see if we can piece it all together.",
        'translate.the_security_tapes.timer.message1': 'Make sure you focus on the location of each camera. For some suspects it is important where they are not seen on camera.',
        'translate.the_security_tapes.timer.message2': 'Peach is never seen on the camera near room 247, so she must ended up in room 248. The Boy starts in room 246.',
        'translate.the_security_tapes.hint.explanation': "You need to figure out where each person was right before the blackout. First notice the different camera positions.",
        'translate.the_security_tapes.hint.hint1': "Make sure you focus on the location of each camera. For some suspects it is important where they are not seen on camera.",
        'translate.the_security_tapes.hint.hint2': "Peach is never seen on the camera near room 247, so she must ended up in room 248. The Boy starts in room 246.",
        'translate.the_security_tapes.hint.solution': "The Caregiver starts in room 247 and ends up in the bar. The Baron starts in the Lobby and ends up in the bar. Peach comes out of the bar and ends up in room 248. You cannot access the storage and she is not seen on camera near 247. The Owner starts and ends in his office. The Lady starts in room 247 and ends up in the hallway. Finally, the Boy starts in room 246 and ends up in the lobby.",

        'translate.the_security_tapes.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPmap.webp",
        'translate.the_security_tapes.characterlist.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcharacterlist.webp",
        'translate.the_security_tapes.2p.player.gap_fill_text': `The Caregiver started in <gap id="1" />, right before the blackout he was in <gap id="2" />. Peach started in <gap id="3" />, right before the blackout she was in <gap id="4" />. The Owner started in <gap id="5" />, right before the blackout he was in <gap id="6" />.`,
        'translate.the_security_tapes.2p.guest.gap_fill_text': `The Boy started in <gap id="1" />, right before the blackout he was in <gap id="2" />. The Lady started in <gap id="3" />, right before the blackout she was in <gap id="4" />. The Baron started in <gap id="5" />, right before the blackout he was in <gap id="6" />.`,

        'translate.the_recap.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover9.webp",
        'translate.the_recap.timer.title': "9. The Recap - A Case in Paradise",
        'translate.the_recap.timer.subtitle': "You will receive more information in:",
        'translate.the_recap.message1.title': "We know where they were right before the blackout. Now let’s take a step back and go over all the details again.",
        'translate.the_recap.message2.title': "Let me know what you think happened.",
        'translate.the_recap.message2.error': "Parts of that sound right, but I believe it is not the full story. Did you notice which things changed after the blackout?",
        'translate.the_recap.message3.title': "That makes sense. Now we only need to find the thief. Or do you already know?",
        'translate.the_recap.timer.message1': 'The blackout is caused by using the remote in the bar, the remote in the lobby and the switch in the hallway. We can be sure who turned on the remote in the lobby and the switch in the hallway.',
        'translate.the_recap.timer.message2': 'The Caregiver and the Lady worked together to cause the blackout.',
        'translate.the_recap.hint.explanation': "You need to put together the case. Focus first on how the blackout was caused and who could have done this.",
        'translate.the_recap.hint.hint1': "The blackout is caused by using the remote in the bar, the remote in the lobby and the switch in the hallway. We can be sure who turned on the remote in the lobby and the switch in the hallway.",
        'translate.the_recap.hint.hint2': "The Caregiver and the Lady worked together to cause the blackout.",
        'translate.the_recap.hint.solution': "The blackout is caused by using the remote in the bar, the remote in the lobby and the switch in the hallway. You see the Caregiver first use the remote in the lobby and the Lady is the only one who was in the hallway before the blackout, so we are sure they worked together. It is likely the Caregiver turned on the remote in the bar as well, but we cannot be certain (it is not asked). During the blackout, the thief first took out the Boy using chloroform. As the thief moved on to the living room, the Lady heard the thief approach and fled into a closet. Next, the thief entered the living room, knocked over a bowl and scared the Lady. The scream alerted the Owner, which meant the thief had to flee through the window.",

        'translate.the_recap.photoshoes.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPphotoshoes.webp",
        'translate.the_recap.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPmap.webp",
        'translate.the_recap.characterlist.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcharacterlist.webp",
       
        'translate.the_recap.gap_fill_text': `
            <p>
            The first action that caused the blackout was done by <gap id="1" /> and we also know that <gap id="2" /> took part in it. They must have worked together. 
            </p>
            <p>
            During the blackout, the thief first took out <gap id="3" /> using chloroform. As the thief moved on to the living room, <gap id="4" /> heard the thief approach and fled into a closet. 
            </p>
            <p>
            Next, the thief entered the living room, knocked over <gap id="5" /> and scared <gap id="6" />. The scream alerted <gap id="7" />, which meant the thief had to flee through <gap id="8" />.
            </p>`,
           

        'translate.whodunnit.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover10.webp",
        'translate.whodunnit.timer.title': "10. Whodunnit - A Case in Paradise",
        'translate.whodunnit.timer.subtitle': "You will receive more information in:",
        'translate.whodunnit.message1.title': "We know what happened. Only one question remains.",
        'translate.whodunnit.message2.title': "Who stole the Knight?",
        'translate.whodunnit.message2.error': "I reviewed the recording, but I don't see this as the crucial piece of evidence against this suspect. Did you notice what changed after the blackout?",
        'translate.whodunnit.message3.title': "Well spotted. I think it's time to wrap this up.",
        'translate.whodunnit.timer.message1': 'There are two main things that changed after the blackout. One is clearly visible, the other one is a detail that has to do with one of the suspects.',
        'translate.whodunnit.timer.message2': 'There is a trail that leads from the front door to the bar. Focus on the suspects in recording 8 to notice the difference before the blackout.',
        'translate.whodunnit.hint.explanation': "You need to put the case together. Use the words provided and focus on identifying the thief. You'll have to point to one specific recording that shows a crucial detail.",
        'translate.whodunnit.hint.hint1': "There are two main things that changed after the blackout. One is clearly visible, the other one is a detail that has to do with one of the suspects.",
        'translate.whodunnit.hint.hint2': "There is a trail that leads from the front door to the bar. Focus on the suspects in recording 8 to notice the difference before the blackout..",
        'translate.whodunnit.hint.solution': "The muddy footprints show that the thief ended up in the bar. Both the Baron and the Caregiver were there. Recording 8 now shows the Baron with white socks instead of his red shoes. His shoes got muddy when he had to flee through the window, so he took them off.",
        
        'translate.whodunnit.photoshoes.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPphotoshoes.webp",
        'translate.whodunnit.map.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPmap.webp",
        'translate.whodunnit.characterlist.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcharacterlist.webp",
        
        'translate.whodunnit.gap_fill_text': `Recording nr. <gap id="1" />  shows crucial evidence that <gap id="2" /> stole the Knight.`,
        
        'translate.the_confrontation.wordpressProductCover.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIPcover11.webp",
        'translate.the_confrontation.timer.title': "11. The End - A Case in Paradise",
        'translate.the_confrontation.timer.subtitle': "The case is closed (don't force skip).",
        'translate.the_confrontation.message1.title': "Well done. You closed the case. Now there is one little thing that I found hidden in the bar. What do you say we do with it? I have an idea... ",
        'translate.the_confrontation.message2.title': "Do you agree?",
        'translate.the_confrontation.message2.error': "Just let me know by typing yes or no",
        'translate.the_confrontation.message3.title': "OK. I'll return the Knight to where it belongs... Thanks for your help detectives! (Click Results to see your score and don't forget to challenge your friends and family!)",
        'translate.the_confrontation.hint.explanation': "The case has been solved (just type yes or no).",
        'translate.the_confrontation.hint.hint1': "No hints here (just type yes or no).",
        'translate.the_confrontation.hint.hint2': "No hints here (just type yes or no).",
        'translate.the_confrontation.hint.solution': "The case is closed.",
        
        'translate.the_confrontation.knight.media': "https://couchclues-game-media.s3.eu-central-1.amazonaws.com/ACIP/ACIP11photoknight.webp",
        
    }
);

export default enPuzzleParadise;
