const nlGame=  ({
    languageLabel: "Taal",
    lang: {
        en: "English",
        nl: "Nederlands",
    },
    copied: "Gekopieerd!",
    continue: "DOORGAAN!",
    playNow: "Speel Nu",
    rejoin: "Opnieuw deelnemen",
    seeResults: "Bekijk de score",
    selectLanguage: "Selecteer de taal",
    go: "DOORGAAN",
    results: "Score",
    completed: "Voltooid:",
    noPuzzles: "Geen puzzels beschikbaar. Koop een spel om te beginnen met spelen!",
    kickedAlert: "Je maakt geen deel meer uit van dit spel. Vraag de host om je opnieuw uit te nodigen als je weer wilt deelnemen.",
    kickedOut: "Je bent uit deze puzzel verwijderd.",
    typeToAnswer: "Typ hier het antwoord",
    clickToTag: "Klik om afbeeldingen te taggen",
    clickToTagAnswer: "Klik om antwoorden te taggen",
    clickToTagAnswerInOrder: "Tag de antwoorden in de juiste volgorde",
    clickToDragFill: "Klik om de open plekken in te vullen",
    backToChat: "Terug naar de chat",
    chatWith: "Chatten met",
    difficulty: "Moeilijkheidsgraad",
    copyLink: "Kopieer deze link en deel hem met je vrienden:",
    copyLinkLabel: "Uitnodigingslink kopiëren",
    inviteFriend: "Nodig een vriend uit",
    about: "Over",
    faq: "FAQ",
    shop: "Shop",
    waitForGo: "Wacht tot de andere speler op DOORGAAN drukt",
    shareAlinkWithPlayer: "Deel een link om een andere speler uit te nodigen",
    askPartnerToStart: "Vraag je medespeler om het spel te starten. We wachten...",
    guestPlayerNotReady: "Je medespeler is nog niet klaar. We wachten... ",
    minimumPlayersText: "Je hebt minimaal twee spelers nodig om het spel te spelen. {!isGuest ? ' Stuur de link naar je medespeler om te kunnen spelen ' : ''}",
    copyInviteLinkLabel: "Uitnodigingslink kopiëren",
    playerWaitingForPartner: " {{partnerName}} moet nog een oplossing doorsturen. Kun jij helpen?",
    difficultyLabel: "Moeilijkheidsgraad",
    startButtonText: "Start het spel",
    playButtonText: "Spelen!",
    shareLinkDialogTitle: "Deel de link hieronder om het spel te starten",
    startGameDialogTitle: "Los het samen op!",
    yourNameLabel: "Jouw naam",
    getStartText: "Start het spel",
    errorGoHome: "Terug naar Home",
    error: "Fout",
    notExists: "Het spel waarnaar je op zoek bent bestaat niet",
    buyAnother: "Je moet een ander hoofdstuk kopen om dit spel te spelen",
    youDontHave: "Je hebt geen toegang tot deze puzzel",
    getPuzzle: "Koop hoofdstuk",
    steveIsTyping: "Steve is aan het typen...",
})

export default nlGame;