import {Box, Collapse, SwipeableDrawer} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import List from "@mui/material/List";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import ListItemText from "@mui/material/ListItemText";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PlayerStat from "./PlayerStat";
import SendIcon from "@mui/icons-material/Send";
import TimerIcon from "@mui/icons-material/Timer";
import {useTranslation} from "react-i18next";
import PuzzleStatsList from "./PuzzleStatsList";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PuzzleStat from "./PuzzleStat";

export default function PuzzleStatsDrawer(
    {
        onOpen,
        onClose,
        isOpen,
        playerStats,
        difficulty,
        completedIn,
        score
    }
) {
    const {t: tPuzzle} = useTranslation('puzzle');
    const {t} = useTranslation('puzzleCard');

    const [solutionsExpanded, setSolutionsExpanded] = useState(false);
    const [hintsUsedExpanded, setHintsUsedExpanded] = useState(false);

    const getMaxHintsUsed = () => Math.max(...playerStats.map(item => item.hintsUsed));

    const onHintsUsedExpand = (e) => {
        e.stopPropagation();
        setHintsUsedExpanded(prev => !prev);
    }

    const onSolutionsTriedExpand = (e) => {
        e.stopPropagation();
        setSolutionsExpanded(prev => !prev);
    }

    const getMinSolutionsTried = () => playerStats.reduce((min, item) => (
        item?.solutionsTried < min ? item?.solutionsTried : min
    ), playerStats[0]?.solutionsTried);

    const getHumanCompletedIn = (completedIn) => {
        if (completedIn !== null) {
            return completedIn;
        }
        return '-';
    }

    const handleClose = () => {
        setSolutionsExpanded(false);
        setHintsUsedExpanded(false);
       return onClose && onClose();
    }

    return (
        <SwipeableDrawer
            anchor={'bottom'}
            open={isOpen}
            onClose={handleClose}
            onOpen={onOpen}
        >
            <Box
                sx={{width: 'auto'}}
                role="presentation"
                onClick={handleClose}
                onKeyDown={handleClose}
            >
                <PuzzleStatsList>
                    <PuzzleStat Icon={EmojiEventsIcon} disablePadding>
                        <ListItemText
                            primary={t('score')}
                            secondary={score?.score}
                        />
                    </PuzzleStat>

                    <PuzzleStat Icon={FitnessCenterIcon} disablePadding >
                        <ListItemText
                            primary={t('difficultyPlayed')}
                            secondary={tPuzzle(`translate.game.${difficulty}`)}
                        />
                    </PuzzleStat>

                    <PuzzleStat Icon={QuestionMarkIcon} disablePadding onClick={onHintsUsedExpand} expanded={hintsUsedExpanded}>
                        <ListItemText
                            primary={t('hintsUsed')}
                            secondary={getMaxHintsUsed()}
                        />
                    </PuzzleStat>
                    <Collapse in={hintsUsedExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{backgroundColor: '#182133'}}>
                            {playerStats.map((it, index) => [<PlayerStat key={index} name={it.memberName} stat={it.hintsUsed} />])}
                        </List>
                    </Collapse>

                    <PuzzleStat Icon={SendIcon} disablePadding onClick={onSolutionsTriedExpand} expanded={solutionsExpanded}>
                        <ListItemText
                            primary={t('solutionsTried')}
                            secondary={getMinSolutionsTried()}
                        />
                    </PuzzleStat>
                    <Collapse in={solutionsExpanded} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding sx={{backgroundColor: '#182133'}}>
                            {playerStats.map((it, index) => [<PlayerStat key={index} name={it.memberName} stat={it.solutionsTried} />])}
                        </List>
                    </Collapse>

                    <PuzzleStat Icon={TimerIcon} disablePadding>
                        <ListItemText
                            primary={t('completedIn')}
                            secondary={getHumanCompletedIn(completedIn)}
                        />
                    </PuzzleStat>
                </PuzzleStatsList>
            </Box>
        </SwipeableDrawer>
    )
}